@charset "utf-8";
/* CSS Document */
.dt-sc-one-column {
  width: 100%;
  margin: 0px;
  padding: 0px;
  float: left;
}

.dt-sc-one-half {
  width: 48.9%;
}

.dt-sc-one-third {
  width: 32%;
}

.dt-sc-one-fourth {
  width: 23.5%;
}

.dt-sc-one-fifth {
  width: 18.4%;
}

.dt-sc-two-third {
  width: 65.8%;
}

.dt-sc-three-fourth {
  width: 74.5%;
}

.column.no-space.dt-sc-one-third {
  width: 33.3%;
  margin: 0px;
}

.column.no-space {
  margin-left: 0px;
}

ol.decimal {
  list-style-type: decimal;
}

ol.decimal-leading-zero {
  list-style-type: decimal-leading-zero;
}

ol.lower-alpha {
  list-style-type: lower-alpha;
}

ol.lower-roman {
  list-style-type: lower-roman;
}

ol.upper-alpha {
  list-style-type: upper-alpha;
}

ol.upper-roman {
  list-style-type: upper-roman;
}

ol.dt-sc-fancy-list li span {
  color: #5c5c5c;
}

ol.dt-sc-fancy-list.turquoise li {
  color: #008c99;
}

ol.dt-sc-fancy-list.pink li {
  color: #de81b8;
}

ol.dt-sc-fancy-list.mustard li {
  color: #df9b00;
}

ol.dt-sc-fancy-list.violet li {
  color: #9b59b6;
}

ol.dt-sc-fancy-list.blue li {
  color: #3498db;
}

ol.dt-sc-fancy-list.chocolate li {
  color: #7b4b4b;
}

.column {
  float: left;
  margin: 0px 0px 0px 2%;
}

.column.first {
  margin-left: 0px;
}

.dt-sc-hr {
  width: 100%;
  float: left;
  clear: both;
  margin: 30px 0px;
  padding: 0px;
  display: block;
}

.dt-sc-hr-medium {
  width: 100%;
  float: left;
  clear: both;
  margin: 20px 0px;
  padding: 0px;
  display: block;
}

.dt-sc-hr-small {
  width: 100%;
  float: left;
  clear: both;
  margin: 15px 0px;
  padding: 0px;
  display: block;
}

.dt-sc-hr-very-small {
  width: 100%;
  float: left;
  clear: both;
  margin: 10px 0px;
  padding: 0px;
  display: block;
}

.dt-sc-clear {
  float: none;
  clear: both;
  margin: 0px;
  padding: 0px;
}

.dt-sc-button {
  background: #008c99;
  color: #ffffff;
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  margin: 10px 0px 0px;
  display: inline-block;
}

.dt-sc-button:hover,
.dt-sc-button.burnt-orange:hover,
.dt-sc-button.mustard:hover,
.dt-sc-button.violet:hover,
.dt-sc-button.green:hover,
.dt-sc-button.pink:hover {
  background: #434343;
  color: #ffffff;
}

.dt-sc-button span {
  margin-left: 5px;
}

.dt-sc-button.small {
  font-size: 14px;
  padding: 10px 14px;
}

.dt-sc-button.medium {
  font-size: 15px;
  padding: 14px 18px;
}

.dt-sc-button.large {
  font-size: 20px;
  padding: 16px 25px;
}

.dt-sc-button.xlarge {
  font-size: 22px;
  padding: 20px 26px;
}

.dt-sc-button.burnt-orange {
  background: #cf571b;
}

.dt-sc-button.mustard {
  background: #df9b00;
}

.dt-sc-button.violet {
  background: #9b59b6;
}

.dt-sc-button.green {
  background: #796e1f;
}

.dt-sc-button.pink {
  background: #de81b8;
}

/**social-icons**/

ul.dt-sc-social-icons {
  margin: 0px;
  padding: 0px;
}

ul.dt-sc-social-icons li,
ul.dt-sc-contact-social-icons li {
  display: inline-block;
  padding-bottom: 0px;
}

ul.dt-sc-social-icons li a {
  line-height: 60px;
  font-size: 16px;
  font-weight: bold;
  color: #292929;
  padding: 0px 10px;
}

/*ul.dt-sc-social-icons li:last-child a{padding-right:0px;}*/

/**services**/

.dt-sc-ico-content {
  width: 100%;
  margin: 0px;
  padding: 0px;
  clear: both;
  display: block;
}

.dt-sc-ico-content.type1 {
  text-align: center;
}

.dt-sc-ico-content.type1 .icon {
  display: inline-block;
  width: 118px;
  height: 118px;
  margin-bottom: 25px;
  border-radius: 100%;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  transition: background 0.2s ease-in-out;
  -moz-transition: background 0.2s ease-in-out;
  -webkit-transition: background 0.2s ease-in-out;
}

.dt-sc-ico-content.type1 .icon img,
.dt-sc-ico-content.type1:hover .icon span:before,
.dt-sc-ico-content.type1:hover .icon span {
  border-radius: 100%;
}

.dt-sc-ico-content.type1 .icon > span.icon-outer {
  border: 3px solid #ffffff;
  box-shadow: 0px 0px 0px 2px #ffffff;
  border-radius: 100%;
  position: relative;
}

.dt-sc-ico-content.type1 h4 a {
  text-shadow: none;
  font-size: 24px;
  color: #2b2b2b;
}

.dt-sc-ico-content.type1 span.icon-outer {
  max-width: 118px;
  max-height: 118px;
  display: inline-block;
  background-repeat: no-repeat;
  margin-bottom: 20px;
  transition: all 0.5s linear 0s;
  -moz-transition: all 0.5s linear 0s;
  -webkit-transition: all 0.5s linear 0s;
}

.dt-sc-ico-content.type1:hover .icon span.icon-outer {
  border: 3px solid #008c99;
  box-shadow: none;
}

.dt-sc-ico-content.type1:hover .icon span:before {
  transform: scale(1);
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  opacity: 1;
}

/*.dt-sc-ico-content.type1 .icon span:before{position:absolute; opacity:0; background:rgba(0, 140, 153, 0.5); content:""; width:90px; height:90px; z-index:999; top:0px; bottom:0px; margin:auto; left:0px; right:0px; 
transform:scale(0); -webkit-transform:scale(0); -moz-transform:scale(0); transition:all 0.5s linear 0s; -moz-transition:all 0.5s linear 0s; -webkit-transition:all 0.5s linear 0s;}*/

.dt-sc-ico-content.type1 .infolayer {
  position: absolute;
  opacity: 0;
  background: rgba(0, 140, 153, 0.8);
  width: 90px;
  height: 90px;
  z-index: 9;
  top: 0px;
  bottom: 0px;
  margin: auto;
  left: 0px;
  right: 0px;
  border-radius: 100%;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  transition: all 0.5s linear 0s;
  -moz-transition: all 0.5s linear 0s;
  -webkit-transition: all 0.5s linear 0s;
  transform: scale(0);
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
}

.dt-sc-ico-content.type1:hover .infolayer {
  opacity: 1;
  transform: scale(1);
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
}

.dt-sc-ico-content.type1 .infolayer a {
  width: 35px;
  height: 35px;
  border-radius: 100%;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  line-height: 35px;
  background: #ffffff;
  display: inline-block;
  margin: auto;
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.dt-sc-full-width-content {
  width: 100%;
  float: left;
  clear: both;
  display: block;
  margin: 0px;
  padding: 0px;
}

.fullwidth-background.turquoise-bg .dt-sc-ico-content.type2 h4 a,
.fullwidth-background.turquoise-bg .dt-sc-ico-content.type2 p {
  color: #ffffff;
}

.dt-sc-ico-content.type2 {
  padding: 0px;
  position: relative;
  width: auto;
  display: inline-block;
}

.dt-sc-ico-content.type2 .icon {
  width: 50px;
  text-align: center;
  float: left;
  margin-right: 15px;
}

.fullwidth-background.turquoise-bg .dt-sc-ico-content.type2 .icon span {
  border: 3px solid #ffffff;
  background-color: #ffffff;
  color: #5c5c5c;
}

.dt-sc-ico-content.type2 .icon span {
  width: 46px;
  height: 46px;
  text-align: center;
  font-size: 18px;
  line-height: 43px;
  border: 3px solid #008c99;
  background-color: #008c99;
  border-radius: 100%;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  display: inline-block;
  color: #ffffff;
}

.dt-sc-ico-content.type2 p {
  float: left;
  margin: 5px 0px 0px 0px;
}

.fullwidth-background.turquoise-bg .dt-sc-ico-content.type2:hover .icon span,
.dt-sc-ico-content.type2:hover .icon span {
  border: 3px solid #80c6cc;
}

.dt-sc-ico-content.type2 h4 {
  margin-bottom: 0px;
  line-height: 40px;
}

.fullwidth-background.turquoise-bg .dt-sc-ico-content.type2:hover h4 a {
  color: #ffc907;
}

.dt-sc-ico-content.type2:hover h4 a {
  color: #73d5f0;
}

.dt-sc-ico-content.type3 {
  background: #ffffff;
  padding: 0px 5% 20px;
  width: 100%;
  text-align: center;
}

.dt-sc-ico-content.type3 .icon {
  display: inline-block;
  width: 90px;
  height: 90px;
  text-align: center;
  margin: -45px 0px 20px;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
}

.dt-sc-ico-content.type3:hover .icon {
  background: #df9b00;
}

.dt-sc-ico-content.type3 .icon span {
  font-size: 36px;
  line-height: 90px;
  color: #ffffff;
}

.dt-sc-ico-content.type3 h4 {
  margin-bottom: 15px;
}

/**dt-sc-team**/

.dt-sc-team {
  float: left;
  width: 100%;
  margin: 0px 0px 20px;
  padding: 0px;
  text-align: center;
}

.dt-sc-team .image {
  position: relative;
  float: left;
  overflow: hidden;
}

.dt-sc-team .item-mask,
.author-thumb .item-mask,
.portfolio-thumb .item-mask,
.comment-author .item-mask {
  left: 0;
  position: absolute;
  top: 0;
  transition: all 0.2s ease 0s;
  z-index: 9;
  clear: both;
}

.dt-sc-team .image img,
.author-thumb .image img,
.portfolio-thumb img {
  float: left;
  width: 100%;
  clear: both;
}

.dt-sc-team .image img {
  height: auto;
}

.dt-sc-team .image {
  display: inline-block;
  width: 100%;
  float: none;
  text-align: center;
  margin: 0px 0px 15px;
  max-width: 220px;
  max-height: 192px;
}

.dt-sc-team .team-details {
  text-align: center;
  clear: both;
  float: left;
  width: 100%;
}

.dt-sc-team h4 {
  color: #252525;
  margin: 0px 0px 10px;
}

.dt-sc-team h6 {
  color: #808080;
  font-style: italic;
  font-weight: normal;
  font-size: 16px;
  margin-bottom: 20px;
  font-family: "Lato", sans-serif;
}

/**carousel-arrows**/

.dt-sc-testimonial-carousel-wrapper .carousel-arrows {
  position: absolute;
  top: -40px;
  right: 0px;
  margin: 0px;
  width: auto;
}

.dt-sc-testimonial-carousel-wrapper .carousel-arrows div {
  margin: 0px;
  width: 25px;
  height: 25px;
  display: inline-block;
  border: 1px solid #afb0b0;
  text-align: center;
  border-radius: 100%;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  font-size: 18px;
  color: #5c5c5c;
}

.dt-sc-testimonial-carousel-wrapper .carousel-arrows div:hover {
  color: #ffffff;
  border: 1px solid #008c99;
}

.dt-sc-testimonial-carousel-wrapper .carousel-arrows .testimonial-prev {
  margin-right: 5px;
}
.dt-sc-testimonial-carousel-wrapper .carousel-arrows .testimonial-prev {
  margin-right: 5px;
}

.dt-sc-testimonial-carousel-wrapper .carousel-arrows .testimonial-prev .fa.fa-angle-left {
  margin-left: -1px;
}
.dt-sc-testimonial-carousel-wrapper .carousel-arrows .testimonial-prev .fa.fa-angle-left {
  margin-left: -1px;
}

.dt-sc-testimonial-carousel-wrapper .carousel-arrows .testimonial-next .fa.fa-angle-right {
  margin-left: 1px;
}
.dt-sc-testimonial-carousel-wrapper .carousel-arrows .testimonial-next .fa.fa-angle-right {
  margin-left: 1px;
}

.dt-sc-testimonial-carousel-wrapper1 .carousel-arrows {
  position: absolute;
  top: -40px;
  right: 0px;
  margin: 0px;
  width: auto;
}

.dt-sc-testimonial-carousel-wrapper1 .carousel-arrows a {
  margin: 0px;
  width: 25px;
  height: 25px;
  display: inline-block;
  border: 1px solid #afb0b0;
  text-align: center;
  border-radius: 100%;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  font-size: 18px;
  color: #5c5c5c;
}

.dt-sc-testimonial-carousel-wrapper1 .carousel-arrows a:hover {
  color: #ffffff;
  border: 1px solid #008c99;
}

.dt-sc-testimonial-carousel-wrapper1 .carousel-arrows .testimonial-prev1 {
  margin-right: 5px;
}
.dt-sc-testimonial-carousel-wrapper1 .carousel-arrows .testimonial-prev1 {
  margin-right: 5px;
}

.dt-sc-testimonial-carousel-wrapper1 .carousel-arrows .testimonial-prev1 .fa.fa-angle-left {
  margin-left: -1px;
}
.dt-sc-testimonial-carousel-wrapper1 .carousel-arrows .testimonial-prev1 .fa.fa-angle-left {
  margin-left: -1px;
}

.dt-sc-testimonial-carousel-wrapper1 .carousel-arrows .testimonial-next1 .fa.fa-angle-right {
  margin-left: 1px;
}
.dt-sc-testimonial-carousel-wrapper1 .carousel-arrows .testimonial-next1 .fa.fa-angle-right {
  margin-left: 1px;
}

/**toggle-accordion**/

.dt-sc-toggle-frame-set {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px;
}

.dt-sc-toggle-frame {
  padding-bottom: 20px;
  display: block;
}

.dt-sc-toggle-frame h5.dt-sc-toggle-accordion {
  font-weight: normal;
  font-size: 14px;
  position: relative;
  text-transform: uppercase;
  margin: 0px 0px 0px;
  padding: 0px 0px 0px 20px;
}

.dt-sc-toggle-frame h5 a {
  display: block;
  text-transform: uppercase;
  font-size: 14px;
  color: #5c5c5c;
}

.dt-sc-toggle-frame h5 a:before,
.dt-sc-toggle-frame h5 a:after {
  position: absolute;
  text-decoration: inherit;
  font-size: 14px;
}

.dt-sc-toggle-frame-set .dt-sc-toggle-frame h5.dt-sc-toggle-accordion:before,
.dt-sc-toggle-frame-set .dt-sc-toggle-frame h5.dt-sc-toggle:before {
  content: "\f077";
  top: -6px;
  left: 0px;
  position: absolute;
  font-weight: normal;
  font-size: 14px;
}

.dt-sc-toggle-frame-set .dt-sc-toggle-frame h5.dt-sc-toggle-accordion:after,
.dt-sc-toggle-frame-set .dt-sc-toggle-frame h5.dt-sc-toggle:after {
  content: "\f078";
  top: 4px;
  left: 0px;
  display: inline-block;
  float: left;
  position: absolute;
  font-size: 14px;
}

.dt-sc-toggle-frame-set .dt-sc-toggle-frame h5.active:before,
.dt-sc-toggle-frame-set .dt-sc-toggle-frame h5.active:after,
.dt-sc-toggle-frame-set h5.dt-sc-toggle.active:before {
  content: "";
}

.dt-sc-toggle-frame .dt-sc-toggle-content {
  padding: 5px 20px 20px 22px;
  margin: 0px 0px 0px 0px;
  background: #ffffff;
}

.dt-sc-toggle-frame .dt-sc-toggle-accordion.active,
.dt-sc-toggle-frame .dt-sc-toggle.active {
  background: #ffffff;
  margin-bottom: 0px;
  padding: 20px 10px 5px 20px;
}

.dt-sc-toggle-accordion.active a:before,
.dt-sc-toggle-accordion.active a:after,
.dt-sc-toggle-frame .dt-sc-toggle.active a:before,
.dt-sc-toggle-frame .dt-sc-toggle.active a:after,
.dt-sc-toggle-frame-set .dt-sc-toggle-frame h5.dt-sc-toggle.active:before {
  content: "";
}

.dt-sc-toggle-frame-set h5.dt-sc-toggle-accordion:before,
h5.dt-sc-toggle:before {
  content: "\f107";
}

h5.dt-sc-toggle-accordion.active:before,
.dt-sc-toggle-frame-set h5.dt-sc-toggle.active:before {
  content: "\f106";
}

.course h5.dt-sc-toggle-accordion:before {
  content: "?";
}

.course h5.dt-sc-toggle-accordion.active:before {
  content: "?";
}

.dt-sc-toggle-frame h5.dt-sc-toggle-accordion:before {
  content: "";
}

.dt-sc-toggle-frame h5.dt-sc-toggle-accordion {
  line-height: inherit;
}

h5.dt-sc-toggle-accordion,
.dt-sc-toggle-frame h5.dt-sc-toggle,
h5.dt-sc-toggle {
  padding: 0px 0px 0px 42px;
  line-height: 30px;
  position: relative;
  font-size: 16px;
  margin-bottom: 10px;
}

.dt-sc-toggle-frame h5.dt-sc-toggle {
  padding: 0px 0px 0px 20px;
  margin-bottom: 0px;
}

.dt-sc-toggle-frame h5.dt-sc-toggle-accordion a,
.dt-sc-toggle-frame h5.dt-sc-toggle a {
  font-weight: normal;
}

.dt-sc-toggle-frame h5.dt-sc-toggle a {
  font-size: 14px;
}

h5.dt-sc-toggle-accordion a {
  color: #5c5c5c;
  font-weight: bold;
  display: block;
}

h5.dt-sc-toggle a {
  color: #5c5c5c;
  font-size: 16px;
  font-weight: bold;
  display: block;
}

.dt-sc-toggle-frame h5.dt-sc-toggle-accordion a {
  font-weight: normal; /*-webkit-transition:none; -moz-transition:none; -o-transition:none; -ms-transition:none; transition:none;*/
}

h5.dt-sc-toggle-accordion:before,
h5.dt-sc-toggle:before {
  background: #333334;
  display: inline-block;
  width: 31px;
  height: 31px;
  text-align: center;
  line-height: 25px;
  color: #ffffff;
  border: 3px solid #cdd0d0;
  border-radius: 100%;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
}

.dt-sc-toggle-frame h5.dt-sc-toggle-accordion:before,
.dt-sc-toggle-frame h5.dt-sc-toggle:before {
  background: none;
  display: inherit;
  width: auto;
  height: auto;
  text-align: inherit;
  line-height: inherit;
  color: inherit;
  border: none;
  border-radius: 0px;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  position: static;
}

h5.dt-sc-toggle-accordion.active:before,
h5.dt-sc-toggle.active:before {
  border: 3px solid #d2dec3;
  line-height: 24px;
}

.dt-sc-toggle-frame h5.dt-sc-toggle-accordion.active:before,
.dt-sc-toggle-frame h5.dt-sc-toggle.active:before {
  border: 0px;
}

.dt-sc-toggle-content {
  margin-left: 42px;
  padding-bottom: 20px;
  clear: both;
  display: none;
}

/**tabs**/

ul.dt-sc-tabs-frame,
ul.dt-sc-tabs {
  margin: 0px;
  padding: 0px;
}

ul.dt-sc-tabs-frame li {
  display: inline;
  float: left;
  margin: 0px;
  padding: 0px;
}

.dt-sc-tabs-frame-content,
.dt-sc-tabs-content {
  background: #ffffff;
  display: block;
  float: left;
  clear: both;
  width: 100%;
  padding: 20px 20px 20px;
  border-radius: 0px 5px 5px 5px;
  -webkit-border-radius: 0px 5px 5px 5px;
  -moz-border-radius: 0px 5px 5px 5px;
  margin: -1px 0px 0px 0px;
}

.dt-sc-tabs-content ul {
  margin: 10px 0px 0px;
}

.dt-sc-tabs-content h2 {
  font-size: 26px;
  margin-bottom: 15px;
}

.dt-sc-tabs-frame-content h5,
.dt-sc-tabs-content h5 {
  font-size: 18px;
  margin-bottom: 15px;
}

.dt-sc-tabs-frame-content {
  background: #ede7d6;
  margin-top: -1px;
  border: 1px solid #b6a99e;
  padding: 5px 10px;
  width: 100%;
  border-radius: 0px 5px 5px 5px;
  -webkit-border-radius: 0px 5px 5px 5px;
  -moz-border-radius: 0px 5px 5px 5px;
}

ul.dt-sc-tabs li {
  margin-right: 1px;
  float: left;
  display: inline;
  padding-bottom: 0px;
}

ul.dt-sc-tabs-frame li {
  margin: 0px 8px 0px 0px;
}

ul.dt-sc-tabs li a {
  padding: 0px 20px;
  line-height: 40px;
  display: inline-block;
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  position: relative;
  z-index: 1;
  border-radius: 5px 5px 0px 0px;
  -webkit-border-radius: 5px 5px 0px 0px;
  -moz-border-radius: 5px 5px 0px 0px;
  background: #df9b00;
}

ul.dt-sc-tabs-frame li a {
  background: #d7cbbb;
  padding: 10px 15px 8px;
  float: left;
  position: relative;
  z-index: 1;
}

ul.dt-sc-tabs-frame li a.current,
ul.dt-sc-tabs li a.current,
ul.dt-sc-tabs-vertical-frame li a.current {
  background: #cf571b;
  color: #ffffff;
}

.dt-sc-tabs-frame-content h5 {
  margin: 15px 0px 10px;
}

.dt-sc-tabs-frame-content p {
  margin: 0px 0px 10px 0px;
}

/**vertical-tabs**/

.dt-sc-tabs-vertical-container {
  float: left;
  width: 100%;
  margin: 0px 0px 20px;
  padding: 0px;
  clear: both;
}

ul.dt-sc-tabs-vertical-frame {
  float: left;
  width: 30%;
  margin: 0px -1px 0px 0px;
  padding: 0px;
}

ul.dt-sc-tabs-vertical-frame li {
  float: left;
  display: block;
  margin: 0px 0px 1px;
  padding: 0px;
  width: 100%;
  position: relative;
}

ul.dt-sc-tabs-vertical-frame li a {
  display: block;
  background: #df9b00;
  border-radius: 5px 0px 0px 5px;
  -webkit-border-radius: 5px 0px 0px 5px;
  -moz-border-radius: 5px 0px 0px 5px;
  margin: 0px;
  padding: 12px;
  font-weight: 600;
  color: #ffffff;
}

.dt-sc-tabs-vertical-frame-content {
  background: #ffffff;
  border-radius: 0px 5px 5px 5px;
  -webkit-border-radius: 0px 5px 5px 5px;
  -moz-border-radius: 0px 5px 5px 5px;
  float: left;
  width: 70%;
  margin: 0px;
  padding: 20px 4% 20px;
}

/**testimonial-carousel**/

.dt-sc-testimonial-carousel-wrapper {
  float: left;
  clear: both;
  margin: 0px 0px 0px;
  padding: 0px;
  width: 100%;
  position: relative;
  display: block;
}

.dt-sc-testimonial-carousel {
  margin: 0px;
  padding: 0px;
  float: left;
  width: 100%;
}

.dt-sc-testimonial-carousel li {
  display: block;
  float: left;
}

.dt-sc-testimonial-carousel-wrapper1 {
  float: left;
  clear: both;
  margin: 0px 0px 0px;
  padding: 0px;
  width: 100%;
  position: relative;
  display: block;
}

.dt-sc-testimonial-carousel1 {
  margin: 0px;
  padding: 0px;
  float: left;
  width: 100%;
}

.dt-sc-testimonial-carousel1 li {
  display: block;
  float: left;
}

.dt-sc-testimonial {
  position: relative;
  float: left;
  display: block;
  width: 100%;
}

.dt-sc-testimonial blockquote {
  position: relative;
  color: #ffffff;
  padding: 15px 56px 15px 56px;
  float: left;
  display: block;
  margin: 0px;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  margin: 0px 0px 25px 0px;
  font-size: 14px;
  line-height: inherit;
  border: none;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
}

.dt-sc-testimonial blockquote q:before,
.dt-sc-testimonial blockquote q:after {
  font-size: 78px;
  font-family: Georgia, serif;
  font-style: italic;
  position: absolute;
}

.dt-sc-testimonial blockquote q:after {
  bottom: -60px;
  right: 25px;
}

.dt-sc-testimonial blockquote q:before {
  top: -15px;
  left: 10px;
}

.dt-sc-testimonial .author {
  float: left;
  margin: 0px 0px 0px 3px;
  display: block;
  width: 88px;
}

.dt-sc-testimonial blockquote:after {
  content: "";
  border-width: 15px 15px 0px 15px;
  border-style: solid;
  border-color: #008c99 transparent transparent;
  width: 0;
  height: 0;
  position: absolute;
  bottom: -15px;
  left: 25px;
}

.dt-sc-testimonial .author img {
  border: 4px solid #ffffff;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  box-shadow: 0px 0px 2px 2px #dbddde;
  -moz-box-shadow: 0px 0px 2px 2px #dbddde;
  -webkit-box-shadow: 0px 0px 2px 2px #dbddde;
  float: left;
  display: inline-block;
  margin: 0px 10px 0px 0px;
}

.dt-sc-testimonial .author-meta p {
  font-weight: bold;
  font-size: 18px;
  color: #000000;
}

.dt-sc-testimonial .author-meta {
  float: left;
  margin: 0px 0px 0px 0px;
  width: 84%;
}

.dt-sc-testimonial .author-meta span {
  display: block;
  margin-bottom: 8px;
}

/**dt-sc-sponsor-carousel**/

.dt-sc-sponsor-carousel-wrapper {
  float: left;
  clear: both;
  margin: 0px;
  padding: 0px;
  width: 100%;
  position: relative;
  display: block;
}

.dt-sc-sponsor-carousel li {
  display: inline-block;
  float: left;
  margin: 0px 10px;
  text-align: center;
}

.dt-sc-sponsor-carousel-wrapper .carousel-arrows {
  position: absolute;
  top: -55px;
  right: 0px;
}

.dt-sc-sponsor-carousel-wrapper .carousel-arrows a {
  background: url(../images/sponsor-carousel-arrows.png) no-repeat;
  width: 14px;
  height: 28px;
  float: left;
  text-align: center;
  margin-left: 15px;
  opacity: 0.5;
  filter: alpha(opacity=50);
}

.dt-sc-sponsor-carousel-wrapper .carousel-arrows a:hover {
  opacity: 1;
  filter: alpha(opacity=100);
}

.dt-sc-sponsor-carousel-wrapper .carousel-arrows a.sponsor-prev {
  background-position: left top;
}

.dt-sc-sponsor-carousel-wrapper .carousel-arrows a.sponsor-next {
  background-position: right top;
}

/**tooltip**/

.dt-sc-boxed-tooltip {
  display: inline-block;
  margin: 0px 0px 0px;
  padding: 0px 20px;
  text-align: center;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}

#tiptip_holder {
  display: none;
  background: #e7e5e5;
  position: absolute;
  top: 0px;
  left: 0;
  z-index: 99999;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}

.tip_bottom #tiptip_content {
  box-shadow: inset 0px -1px 1px 0px #d9d9d9;
  -webkit-box-shadow: inset 0px -1px 1px 0px #d9d9d9;
  -moz-box-shadow: inset 0px -1px 1px 0px #d9d9d9;
}

.tip_top #tiptip_content {
  box-shadow: inset 0px 1px 1px 0px #d9d9d9;
  -webkit-box-shadow: inset 0px 1px 1px 0px #d9d9d9;
  -moz-box-shadow: inset 0px 1px 1px 0px #d9d9d9;
}

#tiptip_content {
  padding: 4px 10px;
  color: #333334;
  font-size: 12px;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
}

#tiptip_arrow,
#tiptip_arrow_inner {
  position: absolute;
  border-color: transparent;
  border-style: solid;
  border-width: 6px;
  height: 0;
  width: 0;
}

#tiptip_holder.tip_top #tiptip_arrow {
  border-top-color: #fff;
  border-top-color: #e7e5e5;
}

#tiptip_holder.tip_bottom #tiptip_arrow {
  border-bottom-color: #fff;
  border-bottom-color: #e7e5e5;
}

#tiptip_holder.tip_right #tiptip_arrow {
  border-right-color: #fff;
  border-right-color: #e7e5e5;
}

#tiptip_holder.tip_left #tiptip_arrow {
  border-left-color: #fff;
  border-left-color: #e7e5e5;
}

#tiptip_holder.tip_top #tiptip_arrow_inner {
  margin-top: -7px;
  margin-left: -6px;
}

#tiptip_holder.tip_bottom #tiptip_arrow_inner {
  margin-top: -5px;
  margin-left: -6px;
}

#tiptip_holder.tip_right #tiptip_arrow_inner {
  margin-top: -6px;
  margin-left: -5px;
}

#tiptip_holder.tip_left #tiptip_arrow_inner {
  margin-top: -6px;
  margin-left: -7px;
}

#tiptip_holder.tip_bottom {
  top: 12px;
}

/**blockquote**/

blockquote {
  padding: 15px 38px;
  margin: 0px;
  position: relative;
  color: #ffffff;
  font-size: 15px;
  line-height: 26px;
  border: 1px dashed rgba(255, 255, 255, 0.5);
  background: #008c99;
  box-shadow: 0px 0px 0px 2px #008c99;
  -webkit-box-shadow: 0px 0px 0px 2px #008c99;
  -moz-box-shadow: 0px 0px 0px 2px #008c99;
}

blockquote q:before,
blockquote q:after {
  font-size: 48px;
  font-family: Georgia, serif;
  font-style: italic;
  position: absolute;
}

blockquote q:before {
  top: 24px;
  left: 10px;
}

blockquote q:after {
  bottom: 0px;
  right: 15px;
}

blockquote.alignright {
  margin-left: 20px;
  text-align: left;
  width: 30%;
  float: right;
}

blockquote.burnt-orange {
  background: #cf571b;
  box-shadow: 0px 0px 0px 2px #cf571b;
  -webkit-box-shadow: 0px 0px 0px 2px #cf571b;
  -moz-box-shadow: 0px 0px 0px 2px #cf571b;
}

blockquote.mustard {
  background: #df9b00;
  box-shadow: 0px 0px 0px 2px #df9b00;
  -webkit-box-shadow: 0px 0px 0px 2px #df9b00;
  -moz-box-shadow: 0px 0px 0px 2px #df9b00;
}

blockquote.violet {
  background: #9b59b6;
  box-shadow: 0px 0px 0px 2px #9b59b6;
  -webkit-box-shadow: 0px 0px 0px 2px #9b59b6;
  -moz-box-shadow: 0px 0px 0px 2px #9b59b6;
}

blockquote.blue {
  background: #73d5f0;
  color: inherit;
  box-shadow: 0px 0px 0px 2px #73d5f0;
  -webkit-box-shadow: 0px 0px 0px 2px #73d5f0;
  -moz-box-shadow: 0px 0px 0px 2px #73d5f0;
}

blockquote.chocolate {
  background: #7b4b4b;
  box-shadow: 0px 0px 0px 2px #7b4b4b;
  -webkit-box-shadow: 0px 0px 0px 2px #7b4b4b;
  -moz-box-shadow: 0px 0px 0px 2px #7b4b4b;
}

blockquote.grey {
  background: #818181;
  box-shadow: 0px 0px 0px 2px #818181;
  -webkit-box-shadow: 0px 0px 0px 2px #818181;
  -moz-box-shadow: 0px 0px 0px 2px #818181;
}

blockquote.green {
  background: #7aa127;
  box-shadow: 0px 0px 0px 2px #7aa127;
  -webkit-box-shadow: 0px 0px 0px 2px #7aa127;
  -moz-box-shadow: 0px 0px 0px 2px #7aa127;
}

blockquote.pink {
  background: #d074b3;
  box-shadow: 0px 0px 0px 2px #d074b3;
  -webkit-box-shadow: 0px 0px 0px 2px #d074b3;
  -moz-box-shadow: 0px 0px 0px 2px #d074b3;
}

/**dt-sc-pullquote**/

.dt-sc-pullquote {
  border-left: 3px solid #008c99;
  background: #ffffff;
  letter-spacing: 0;
  line-height: 25px;
  padding: 15px;
  width: 36%;
}

.dt-sc-pullquote.alignright {
  margin: 20px 0px 20px 20px;
}

.dt-sc-pullquote.alignleft {
  margin: 20px 20px 20px 0px;
}

.dt-sc-pullquote.aligncenter {
  margin: 20px 0px 20px 0px;
  text-align: center;
  display: block;
  width: 100%;
}

/**lists***/

ul.dt-sc-fancy-list li {
  display: block;
  position: relative;
  padding-left: 25px;
  line-height: normal;
}

ul.dt-sc-fancy-list li:before {
  color: #868686;
  text-decoration: inherit;
  font-size: 14px;
  position: absolute;
  top: 2px;
  left: 0px;
}

ul.dt-sc-fancy-list.thumbs-up li:before {
  content: "\f088";
}

ul.dt-sc-fancy-list.thumbs-down li:before {
  content: "\f087";
}

ul.dt-sc-fancy-list.gift li:before {
  content: "\f06b";
}

ul.dt-sc-fancy-list.tree li:before {
  content: "\f1bb";
}

ul.dt-sc-fancy-list.star li:before {
  content: "\f005";
}

ul.dt-sc-fancy-list.smile li:before {
  content: "\f118";
}

ul.dt-sc-fancy-list.music li:before {
  content: "\f001";
}

ul.dt-sc-fancy-list.moon li:before {
  content: "\f186";
}

ul.dt-sc-fancy-list.car li:before {
  content: "\f1b9";
}

ul.dt-sc-fancy-list.graduation-cap li:before {
  content: "\f19d";
}

ul.dt-sc-fancy-list.reddit li:before {
  content: "\f1a1";
}

ul.dt-sc-fancy-list.turquoise li:before {
  color: #008c99;
}

ul.dt-sc-fancy-list.pink li:before {
  color: #de81b8;
}

ul.dt-sc-fancy-list.mustard li:before {
  color: #df9b00;
}

ul.dt-sc-fancy-list.violet li:before {
  color: #9b59b6;
}

ul.dt-sc-fancy-list.blue li:before {
  color: #3498db;
}

ul.dt-sc-fancy-list.chocolate li:before {
  color: #7b4b4b;
}

ul.dt-sc-fancy-list.burnt-orange li:before {
  color: #cf571b;
}

ul.dt-sc-fancy-list.red li:before {
  color: #cb4d40;
}

/**notification boxes**/

.dt-sc-error-box p,
.dt-sc-warning-box p,
.dt-sc-success-box p,
.dt-sc-info-box p {
  margin-bottom: 0;
  line-height: 20px;
}

.dt-sc-error-box,
.dt-sc-warning-box,
.dt-sc-success-box,
.dt-sc-info-box {
  clear: both;
  margin-bottom: 25px;
  padding: 15px 15px 12px 42px;
}

.dt-sc-error-box a,
.dt-sc-warning-box a,
.dt-sc-success-box a,
.dt-sc-info-box a {
  text-decoration: underline;
}

.dt-sc-error-box a:hover,
.dt-sc-warning-box a:hover,
.dt-sc-success-box a:hover,
.dt-sc-info-box a:hover {
  text-decoration: none;
}

.dt-sc-error-box {
  background: #ffcccc;
  border: 1px solid #ff9999;
  color: #d01313;
  position: relative;
}

.dt-sc-error-box:before,
.dt-sc-warning-box:before,
.dt-sc-success-box:before,
.dt-sc-info-box:before {
  font-size: 25px;
  left: 12px;
  position: absolute;
  text-decoration: inherit;
  top: 15px;
}

.dt-sc-error-box:before {
  color: #c54228;
  content: "\f057";
}

.dt-sc-warning-box {
  background: #fdebae;
  border: 1px solid #e6c555;
  color: #c79d13;
  position: relative;
}

.dt-sc-warning-box:before {
  color: #e2b828;
  content: "\f06a";
}

.dt-sc-success-box {
  background: #c0df89;
  border: 1px solid #a0ce4e;
  color: #466c00;
  position: relative;
  text-shadow: 1px 1px 0 #e3f1cb;
}

.dt-sc-success-box:before {
  color: #75b632;
  content: "\f058";
}

.success-msg,
.error-msg {
  display: block;
  margin-bottom: 10px;
  clear: both;
}

footer .success-msg {
  color: #5c5c5c;
}

.error-msg {
  color: #ff0000;
}

.dt-sc-info-box {
  background: #bfe4f9;
  border: 1px solid #68a2cf;
  color: #2081b9;
  position: relative;
}

.dt-sc-info-box:before {
  color: #008bc1;
  content: "\f05a";
}

.dt-sc-error-box a {
  color: #d01313;
}

.dt-sc-warning-box a {
  color: #c79d13;
}

.dt-sc-success-box a {
  color: #7ba411;
}

.dt-sc-info-box a {
  color: #2081b9;
}

/**progress bars**/

.dt-sc-progress {
  height: 42px;
  margin-bottom: 10px;
  clear: both;
  display: block;
}

.dt-sc-progress .dt-sc-bar {
  position: relative;
}

.dt-sc-bar-text {
  margin-bottom: 5px;
}

.dt-sc-progress .dt-sc-bar-text {
  font-size: 14px;
  position: absolute;
  top: 0;
  left: 0;
  line-height: 42px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0px 20px;
  color: #ffffff;
  font-weight: bold;
  width: 100%;
  text-align: left;
  display: none;
}

.dt-sc-progress .dt-sc-bar-text > span {
  float: right;
  display: block;
}

.dt-sc-progress {
  overflow: hidden;
  height: 20px;
  background-color: #ffffff;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
}

.dt-sc-progress .dt-sc-bar {
  width: 0%;
  height: 100%;
  color: #ffffff;
  float: left;
  font-size: 12px;
  text-align: center;
  background-repeat: repeat-x;
  -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  -moz-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: width 0.6s ease;
  -moz-transition: width 0.6s ease;
  -o-transition: width 0.6s ease;
  transition: width 0.6s ease;
}

.dt-sc-progress .dt-sc-bar + .dt-sc-bar {
  -webkit-box-shadow: inset 1px 0 0 rgba(0, 0, 0, 0.15), inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  -moz-box-shadow: inset 1px 0 0 rgba(0, 0, 0, 0.15), inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  box-shadow: inset 1px 0 0 rgba(0, 0, 0, 0.15), inset 0 -1px 0 rgba(0, 0, 0, 0.15);
}

.dt-sc-progress-striped .dt-sc-bar {
  background-image: -webkit-gradient(
    linear,
    0 100%,
    100% 0,
    color-stop(0.25, rgba(255, 255, 255, 0.15)),
    color-stop(0.25, transparent),
    color-stop(0.5, transparent),
    color-stop(0.5, rgba(255, 255, 255, 0.15)),
    color-stop(0.75, rgba(255, 255, 255, 0.15)),
    color-stop(0.75, transparent),
    to(transparent)
  );
  background-image: -webkit-linear-gradient(
    -45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: -moz-linear-gradient(
    -45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: -o-linear-gradient(
    -45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: linear-gradient(
    -45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  -webkit-background-size: 20px 20px;
  -moz-background-size: 20px 20px;
  -o-background-size: 20px 20px;
  background-size: 20px 20px;
}
.dt-sc-progress.active .dt-sc-bar {
  -webkit-animation: progress-bar-stripes 2s linear infinite;
  -moz-animation: progress-bar-stripes 2s linear infinite;
  -ms-animation: progress-bar-stripes 2s linear infinite;
  -o-animation: progress-bar-stripes 2s linear infinite;
  animation: progress-bar-stripes 2s linear infinite;
}
@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }
  to {
    background-position: 0 0;
  }
}
@-moz-keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }
  to {
    background-position: 0 0;
  }
}
@-ms-keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }
  to {
    background-position: 0 0;
  }
}
@-o-keyframes progress-bar-stripes {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 40px 0;
  }
}
@keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }
  to {
    background-position: 0 0;
  }
}

/**pricing table**/

.dt-sc-pricing-table.no-space .dt-sc-one-fourth {
  width: 25%;
}

.dt-sc-pricing-table.no-space .column {
  margin-left: 0px;
}

.dt-sc-pr-tb-col.selected {
  border: 1px solid #008c99;
}

.dt-sc-pr-tb-col.mustard {
  background: #efc043;
  border-color: #ca8d02;
}

.dt-sc-pr-tb-col.green {
  background: #d5c651;
  border-color: #c2b130;
}

.dt-sc-pr-tb-col.pink {
  background: #f5bbd1;
  border-color: #de81b8;
}

.dt-sc-pr-tb-col.blue {
  background: #a9e1f9;
  border-color: #5fa9c9;
}

.dt-sc-pr-tb-col.mustard .dt-sc-tb-header .dt-sc-price {
  background: #df9b00;
}

.dt-sc-pr-tb-col.green .dt-sc-tb-header .dt-sc-price {
  background: #c2b130;
}

.dt-sc-pr-tb-col.pink .dt-sc-tb-header .dt-sc-price {
  background: #de81b8;
}

.dt-sc-pr-tb-col.blue .dt-sc-tb-header .dt-sc-price {
  background: #5fa9c9;
}

.dt-sc-pr-tb-col.mustard .dt-sc-tb-content li {
  border-bottom: 1px solid #ca8d02;
}

.dt-sc-pr-tb-col.green .dt-sc-tb-content li {
  border-bottom: 1px solid #c2b130;
}

.dt-sc-pr-tb-col.pink .dt-sc-tb-content li {
  border-bottom: 1px solid #de81b8;
}

.dt-sc-pr-tb-col.blue .dt-sc-tb-content li {
  border-bottom: 1px solid #5fa9c9;
}

.dt-sc-tb-header-img img {
  position: absolute;
  top: 10px;
  right: 0px;
  z-index: -10;
  display: inline-block;
}

.dt-sc-pr-tb-col:hover .dt-sc-tb-header-img img {
  top: -35px;
  right: -25px;
  z-index: 10;
}

.dt-sc-tb-header .dt-sc-tb-thumb > img {
  float: left;
  position: relative;
  width: 100%;
  z-index: 9;
}

.dt-sc-tb-header .dt-sc-tb-thumb .dt-sc-tb-title {
  bottom: 0;
  display: inline-block;
  left: 0;
  opacity: 0.9;
  position: absolute;
  width: 100%;
  padding: 15px 0px;
  z-index: 9;
}

.dt-sc-tb-header .dt-sc-tb-thumb {
  display: inline-block;
  width: 100%;
  position: relative;
  float: left;
}

.dt-sc-pricing-table {
  width: 100%;
  margin: 30px 0px 20px;
  padding: 0px;
  float: left;
}

.dt-sc-pr-tb-col {
  position: relative;
  border: 1px solid #ca8d02;
  background: #ffffff;
  width: 100%;
  margin: 0px;
  padding: 0px;
  text-align: center;
  -webkit-transition: -webkit-box-shadow 0.2s ease-out;
  -moz-transition: -moz-box-shadow 0.2s ease-out;
  -o-transition: -o-box-shadow 0.2s ease-out;
  transition: box-shadow 0.2s ease-out;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
}

.dt-sc-tb-title h5 {
  padding: 20px;
  font-weight: 600;
  font-size: 18px;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 0px;
}

.dt-sc-tb-title,
.dt-sc-tb-header .dt-sc-tb-thumb > img {
  border-radius: 5px 5px 0px 0px;
  -webkit-border-radius: 5px 5px 0px 0px;
  -moz-border-radius: 5px 5px 0px 0px;
}

.dt-sc-tb-header .dt-sc-price {
  background: #f2f2f2;
  margin: 0px;
  padding: 25px 20px 25px 20px;
  display: block;
  font-size: 48px;
  font-weight: 300;
  color: #181818;
}

.dt-sc-tb-header .dt-sc-price span {
  font-size: 18px;
}

.dt-sc-tb-content {
  list-style-type: none;
  margin: 0px;
}

.dt-sc-tb-content li {
  font-size: 14px;
  color: #333333;
  border-bottom: 1px solid #dfdfdf;
  padding: 18px 10px;
}

.dt-sc-pr-tb-col .dt-sc-buy-now {
  padding: 20px;
  margin: 0px;
}

.dt-sc-pr-tb-col .dt-sc-buy-now .dt-sc-button {
  margin: 0px;
  padding: 10px 20px;
}

.dt-sc-pr-tb-col .dt-sc-buy-now .dt-sc-button:after {
  background: #666667;
}

.dt-sc-pr-tb-col .dt-sc-buy-now .dt-sc-button:hover,
.dt-sc-pr-tb-col.selected .dt-sc-buy-now .dt-sc-button:hover {
  color: #ffffff;
}

.dt-sc-pr-tb-col,
.dt-sc-tb-header .dt-sc-price,
.dt-sc-tb-title h5,
.dt-sc-pr-tb-col .dt-sc-buy-now {
  -webkit-transition: all 300ms linear;
  -moz-transition: all 300ms linear;
  -o-transition: all 300ms linear;
  -ms-transition: all 300ms linear;
  transition: all 300ms linear;
}

.dt-sc-pr-tb-col.selected,
.dt-sc-pr-tb-col:hover {
  z-index: 10;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
  margin-top: -15px;
}

.dt-sc-pr-tb-col:hover {
  z-index: 1;
}

.dt-sc-pr-tb-col.selected .dt-sc-tb-title h5,
.dt-sc-pr-tb-col:hover .dt-sc-tb-title h5,
.dt-sc-pr-tb-col.selected .dt-sc-buy-now,
.dt-sc-pr-tb-col:hover .dt-sc-buy-now {
  padding: 25px 20px;
}

.dt-sc-pr-tb-col.selected .dt-sc-tb-header .dt-sc-price,
.dt-sc-pr-tb-col:hover .dt-sc-tb-header .dt-sc-price {
  padding: 35px 20px;
}

.dt-sc-pr-tb-col.selected .dt-sc-tb-header .dt-sc-price {
  color: #ffffff;
}

.dt-sc-pricing-table.no-space .column {
  margin-left: 0px;
}

.dt-sc-pr-tb-col.mustard .dt-sc-tb-title {
  background: #efc043;
}

.dt-sc-pr-tb-col.green .dt-sc-tb-title {
  background: #d5c651;
}

.dt-sc-pr-tb-col.pink .dt-sc-tb-title {
  background: #f5bbd1;
}

.dt-sc-pr-tb-col.blue .dt-sc-tb-title {
  background: #a9e1f9;
}

.dt-sc-pr-tb-col .dt-sc-tb-title h3 {
  margin-bottom: 0px;
}

.dt-sc-tb-header .dt-sc-price {
  padding: 20px 0px;
  display: inline-block;
  font-size: 46px;
  font-weight: bold;
  color: #ffffff;
  position: relative;
  width: 100%;
}

/**fancy boxes**/

.dt-sc-titled-box h4.dt-sc-titled-box-title {
  color: #ffffff;
}

.dt-sc-titled-box h4.dt-sc-titled-box-title {
  padding: 10px 15px;
  text-shadow: none;
  margin: 0px;
}

.dt-sc-titled-box-content {
  padding: 15px 15px 20px;
  background: #ffffff;
  border: 1px solid #e2e2e2;
  border-top: 0px;
}

.dt-sc-titled-box.green h4.dt-sc-titled-box-title {
  background: #7aa127;
}

.dt-sc-titled-box.mustard h4.dt-sc-titled-box-title {
  background: #df9b00;
}

.dt-sc-titled-box.burnt-orange h4.dt-sc-titled-box-title {
  background: #cf571b;
}

.dt-sc-titled-box.blue h4.dt-sc-titled-box-title {
  background: #73d5f0;
}

.dt-sc-titled-box.chocolate h4.dt-sc-titled-box-title {
  background: #7b4b4b;
}

.dt-sc-titled-box.grey h4.dt-sc-titled-box-title {
  background: #818181;
}

.dt-sc-titled-box.pink h4.dt-sc-titled-box-title {
  background: #de81b8;
}

.dt-sc-colored-box {
  width: 100%;
  float: left;
  margin: 0px;
  padding: 15px 5% 20px;
}

.dt-sc-colored-box h4 {
  border-bottom: 1px solid #5c5c5c;
  padding-bottom: 15px;
  margin-bottom: 15px;
}

.dt-sc-colored-box h4 span {
  font-size: 30px;
  margin-right: 10px;
  position: relative;
  top: 3px;
}

.dt-sc-colored-box h4 {
  color: #5c5c5c;
}

.dt-sc-colored-box.light-pink {
  background: #f6d4e1;
}

.dt-sc-colored-box.pink {
  background: #f3c5d7;
}

.dt-sc-colored-box.dark-pink {
  background: #f2b7ce;
}

.widget.mailchimp p.error {
  border: 1px solid red;
}

.dt-sc-ico-content.type3:hover h4 a {
  color: #434343 !important;
}
