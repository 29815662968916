#about {
  top: 300px;
  background-color: #f7c44e;
}

#blog {
  top: 80px;
  background-color: #2196f3;
}

#projects {
  top: 140px;
  background-color: #f44336;
}

#contact {
  top: 200px;
  background-color: #555;
}

blockquote.hello {
  background: #f27b81;
  box-shadow: 0px 0px 0px 2px #f27b81;
  -webkit-box-shadow: 0px 0px 0px 2px #f27b81;
  text-align: center;
}
.dt-sc-testimonial .author-meta {
  padding: 0px 0px 0px 15px;
}
* {
  margin: 0px;
  padding: 0px;
}
#suggestPost {
  background: transparent url(../images/suggest-post.png) no-repeat scroll 0px 0px;
  height: 170px;
  left: 0;
  position: fixed;
  top: 150px;
  width: 40px;
  z-index: 9999999999;
}
div#suggestPost:hover {
  background-position: -41px 0px;
}
#suggestPost a {
  display: block;
  height: 170px;
  width: 40px;
}
