@charset "utf-8";

/* CSS Document */

/*--------------------------------------------------------------

>>> TABLE OF CONTENTS:

----------------------------------------------------------------

1.0 - General Styles

2.0 - Font Family

3.0 - Header Styles

	3.1 - Menu styles

4.0 - Main Styles

	4.1 - Breadcrumb Styles

	4.2 - Add slider Styles

5.0 - Portfolio Styles

6.0 - Team Styles

7.0 - Blog Styles

	7.1 - Blog Comments Styles

8.0 - Shop Page Styles

9.0 - Contact Page Styles

10.0 - Sidebar Styles

11.0 - Footer Styles

--------------------------------------------------------------*/

/*--------------------------------------------------------------

1.0 - General Styles

--------------------------------------------------------------*/

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

::selection {
  background-color: #008c99;
  color: #ffffff;
}

body {
  background: #f0f3f3;
  color: #5c5c5c;
  margin: 0px;
  padding: 0px;
  font-family: "Lato", sans-serif !important;
  font-size: 14px !important;
  line-height: 22px;

  /* Disable select text */

  /* -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; */
}

body #main {
  background: #f0f3f3 !important;
  /* margin-bottom: 1rem; */
}

.slider-wrapper,
.slide {
  height: 100% !important;
}

.files {
  border: 1px solid rgba(0, 0, 0, 0.23);
  padding: 10px;
  cursor: pointer;
  border-radius: 4px;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
menu,
nav,
section {
  display: block;
}

a {
  text-decoration: none !important;
}

q:before {
  content: "\201c";
}

q:after {
  content: "\201d";
}

a,
.textWrapper,
ul.products .image-overlay,
footer .widget_recent_entries ul li img,
footer .widget_recent_entries ul li,
input[type="submit"],
.entry-thumb,
input[type="submit"],
.dt-sc-ico-content.type2 .icon span,
.dt-sc-ico-content.type1 .infolayer,
.dt-sc-team .team-details h4,
h5.dt-sc-toggle,
.dt-sc-ico-content.type3 .icon,
.dt-sc-ico-content.type3 .icon span,
button[type="submit"],
.dt-sc-tb-header-img img,
h5.dt-sc-toggle-accordion:before,
h5.dt-sc-toggle:before,
.dt-sc-toggle-frame h5.dt-sc-toggle,
h5.dt-sc-toggle,
h5.dt-sc-toggle-accordion:before,
h5.dt-sc-toggle:before,
.dt-sc-toggle-frame h5 span:before,
.dt-sc-toggle-frame h5 span:after,
.dt-sc-toggle-frame h5 span,
.front-portfolio .image-overlay,
.dt-sc-toggle-frame h5 a:before,
.dt-sc-toggle-frame h5 a:after,
.dt-sc-toggle-frame,
h5.dt-sc-toggle-accordion,
h5.dt-sc-toggle,
header ul.dt-sc-social-icons li a:after {
  -webkit-transition: all 300ms linear;
  -moz-transition: all 300ms linear;
  -o-transition: all 300ms linear;
  -ms-transition: all 300ms linear;
  transition: all 300ms linear;
}

a,
.dt-sc-ico-content:hover h4 a,
footer .widget ul li:before,
footer .widget.widget_text ul li a:hover,
footer .widget_recent_entries ul li:hover h6 a,
ul.tweet_list li a,
footer .dt-sc-contact-info a:hover,
footer .dt-sc-contact-info span,
.mailchimp-form p span,
.dt-sc-ico-content.type1:hover h4 a,
.fullwidth_banner h1,
.audio-list h5 a:hover,
.author-description h5 a:hover,
.events-list h2 a:hover,
h5.dt-sc-toggle-accordion a:hover,
h5.dt-sc-toggle-accordion.active a,
.dt-sc-team:hover .team-details h4,
.portfolio-detail h5 a:hover,
.highlighter,
ul.dt-sc-tabs-frame li a.current,
ul.dt-sc-tabs li a.current,
.entry-title h3,
.pagination ul li a,
.pagination .next-post a,
.pagination .prev-post a,
.summary p.price span,
ul.dt-sc-tabs-vertical-frame li a.current,
.dt-sc-toggle-frame h5 a:hover,
.dt-sc-toggle-frame h5.active a,
h5.dt-sc-toggle a:hover,
h5.dt-sc-toggle.active a,
ul.dt-sc-social-icons li a:hover,
#main-menu .megamenu-child-container .widget ul.product_list_widget li a:hover,
#main-menu
  .megamenu-child-container
  .widget
  ul.product_list_widget
  li
  span.amount {
  color: #008c99;
}

a.highlighter:hover,
.portfolio-title p a:hover {
  border-bottom: 1px dotted #008c99;
}

.copyright,
.widget .mailchimp-form input[type="submit"],
.dt-sc-music-audio,
.dt-sc-testimonial blockquote,
.fullwidth-background.turquoise-plain-bg,
h5.dt-sc-toggle-accordion.active:before,
.dt-sc-testimonial-carousel-wrapper1 .carousel-arrows a:hover,
.dt-sc-testimonial-carousel-wrapper .carousel-arrows a:hover,
.dt-sc-ico-content.type1 .infolayer a,
.date,
.comments,
.entry_format,
.dt-sc-ico-content.type3 .icon,
.pagination ul li a:hover,
.pagination .next-post a:hover,
.pagination .prev-post a:hover,
form.cart input[type="button"],
th,
h5.dt-sc-toggle.active:before,
.dt-sc-pr-tb-col.selected .dt-sc-tb-header .dt-sc-price,
.class_hours h2,
.dt-sc-titled-box h4.dt-sc-titled-box-title,
.mean-container a.meanmenu-reveal,
.mean-nav,
header ul.dt-sc-social-icons li a:after,
.dt-menu-toggle {
  background: #008c99;
}

a:hover,
ul.tweet_list li a:hover,
.copyright p a:hover,
.breadcrumb-section .breadcrumb a:hover,
.sorting-container a,
.portfolio-detail h5 a,
.fullwidth-background.orange-bg a:hover,
.dt-sc-ico-content.type3:hover h4 a {
  color: #434343;
}

p {
  margin: 0px 0px 10px;
  padding: 0px;
}

img {
  border: none;
  max-width: 100%;
}

ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
}

ol {
  margin: 0px;
  padding: 0px;
  list-style-position: outside;
  padding-left: 20px;
}

ul li,
ol li {
  padding-bottom: 10px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
  padding: 0px;
  color: #2f2f2f;
}

h1 {
  font-size: 32px;
  margin-bottom: 25px;
}

h2 {
  font-size: 30px;
  margin-bottom: 25px;
}

h3 {
  font-size: 24px;
  margin-bottom: 15px;
}

h4 {
  margin-bottom: 20px;
  font-size: 20px;
}

h5 {
  font-size: 18px;
  margin-bottom: 20px;
}

h6 {
  margin-bottom: 15px;
  font-size: 16px;
}

sub,
sup {
  font-size: 14px;
  line-height: 0;
  vertical-align: baseline;
}

del {
  text-decoration: line-through;
}

table {
  border-collapse: separate;
  border-spacing: 0;
  margin-bottom: 20px;
  width: 100%;
  clear: both;
  border: 1px solid #f0f0f0;
  border-bottom: 0px;
}

th {
  color: #ffffff;
  border-bottom: 1px solid #f0f0f0;
  padding: 15px;
  line-height: normal;
  text-transform: uppercase;
  text-align: center;
}

td {
  border-bottom: 1px solid #f0f0f0;
  background: #f9f9f9;
  line-height: normal;
  text-align: center;
  padding: 13px 15px;
}

tbody tr:nth-child(2n + 1) td {
  background: #ffffff;
}

tbody td .dt-sc-button.small {
  padding: 7px 14px;
  font-size: 12px;
  margin: 0px;
}

iframe {
  max-width: 100%;
  border: none;
}

#map {
  width: 1170px;
  height: 500px;
  max-width: 100%;
}

.aligncenter {
  text-align: center;
  width: 100%;
  display: inline-block;
  margin: 0 auto;
}

.alignleft {
  float: left;
}

.alignright {
  float: right;
}

blockquote q {
  font-style: italic;
}

input[type="text"],
input[type="email"] {
  width: 100%;
  padding: 16px 18px;
  font-size: 16px;
  color: #a9a7a7;
  background: #ffffff;
  border: 1px solid #eaeaea;
  outline: none;
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
}

input[type="text"]:focus,
input[type="email"]:focus,
textarea:focus {
  box-shadow: 0px 0px 10px #ddd;
  -webkit-box-shadow: 0px 0px 10px #ddd;
  -moz-box-shadow: 0px 0px 10px #ddd;
}

.fullwidth-background.turquoise-plain-bg input[type="text"]:focus,
.fullwidth-background.turquoise-plain-bg input[type="email"]:focus,
.fullwidth-background.turquoise-plain-bg textarea:focus {
  box-shadow: 0px 0px 10px #015057;
}

input[type="button"] {
  border: 0px;
  outline: none;
  cursor: pointer;
}

input[type="submit"] {
  background: #015057;
  color: #ffffff;
  font-family: inherit;
  padding: 18px 24px;
  font-size: 14px;
  border: none;
  outline: none;
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  cursor: pointer;
}

input[type="submit"],
button,
input[type="button"],
button[type="submit"] {
  -webkit-appearance: none;
}

input[type="submit"]:hover {
  background: #333334;
  color: #ffffff;
}

input[type="number"] {
  background: #ffffff;
  border: 1px solid #dddddd;
  padding: 12px 5px 12px 10px;
  display: block;
  margin: 10px 0 10px;
  width: 100%;
  outline: none;
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

button[type="submit"] {
  cursor: pointer;
  border: 0px;
  outline: none;
}

textarea {
  border: none;
  outline: none;
  font-size: 16px;
  color: #a9a7a7;
  padding: 16px 18px;
  width: 100%;
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
}

input.error,
textarea.error {
  padding: 15px 18px;
  border: 1px solid #ff0000;
}

#primary form p {
  margin-bottom: 20px;
}

/*--------------------------------------------------------------

2.0 - Font Family

--------------------------------------------------------------*/

.dt-sc-toggle-frame h5 a,
.course h5.dt-sc-toggle-accordion:before,
.course h5.dt-sc-toggle-accordion.active:before,
h5.dt-sc-toggle a,
h5.dt-sc-toggle-accordion a,
.product-details h5 a,
.front-portfolio .image-overlay h5 a {
  font-family: "Lato", sans-serif;
}

#main-menu .megamenu-child-container .widget ul.product_list_widget li a,
#main-menu
  .megamenu-child-container
  .widget
  ul.product_list_widget
  li
  span.amount {
  font-family: "Open Sans", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
.dt-sc-ico-content h4 a,
.dt-sc-ico-content.type2 h4 a,
.entry-title h3,
.dt-sc-team h4,
h3.widgettitle,
.dt-sc-music-audio h5 a,
input[type="text"],
input[type="email"],
textarea,
.breadcrumb-section h1,
.activity h4,
.megamenu-child-container > ul.sub-menu > li > a {
  font-family: "Bubblegum Sans", cursive;
  font-weight: normal;
}

footer .widget.widget_text ul li:before,
.portfolio-detail p:before,
h5.dt-sc-toggle-accordion:before,
h5.dt-sc-toggle:before,
.entry-meta .entry_format,
.dt-sc-error-box:before,
.dt-sc-warning-box:before,
.dt-sc-success-box:before,
.dt-sc-info-box:before,
.dt-sc-toggle-frame h5 a:before,
.dt-sc-toggle-frame h5 a:after,
.dt-sc-toggle-frame-set .dt-sc-toggle-frame h5.dt-sc-toggle-accordion:after,
.dt-sc-toggle-frame-set .dt-sc-toggle-frame h5.dt-sc-toggle:after,
ul.dt-sc-fancy-list li:before,
header ul.dt-sc-social-icons li a:before {
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
}

/*--------------------------------------------------------------

3.0 - Header Styles

--------------------------------------------------------------*/

.sticky-wrapper {
  clear: both;
  width: 100%;
  z-index: 9999;
  display: block;
  height: auto !important;
}

.is-sticky header {
  clear: both;
  float: left;
  width: 100%;
}

.is-sticky header .logo a img {
  max-height: 60px;
}

.is-sticky header .contact-details {
  margin: 8px 0px 0px;
}

.sticky-wrapper.is-sticky header #main-menu > ul.menu > li > a {
  line-height: 50px;
}

.is-sticky header #menu-container ul.dt-sc-social-icons {
  height: 50px;
}

.is-sticky header ul.dt-sc-social-icons li a {
  height: 55px;
  line-height: 50px;
}

.is-sticky header ul.dt-sc-social-icons li a span {
  height: 55px;
  line-height: 50px;
}

header {
  position: relative;
  width: 100%;
  float: left;
  margin: 0px;
  padding: 0px;
  background: url(../images/header_bg.png) repeat #ffffff;
  display: block;
  z-index: 9999;
}

.faqTitle {
  color: #008c99;
  font-weight: bold;
  display: block;
  font-family: "Lato", sans-serif;
  cursor: pointer;
}

.is-sticky header #menu-container {
  height: auto;
}

.header_top {
  width: 100%;
  float: left;
  padding: 28px 0px;
  margin: 0px;
}

.logo img {
  display: block;
}

header .contact-details .fa {
  margin-left: 5px;
  color: #252525;
}

header .contact-details {
  float: right;
  margin: 25px 0px 0px;
  padding: 0px;
}

header .contact-details p {
  text-align: right;
  margin: 0px;
}

header ul.dt-sc-social-icons li {
  display: inline;
  float: left;
  margin-right: 1px;
}

header ul.dt-sc-social-icons li:last-child {
  margin-right: 0px;
}

header ul.dt-sc-social-icons li a {
  position: relative;
  border-top: 5px solid;
  display: inline-block;
  width: 50px;
  height: 70px;
  overflow: hidden;
  color: #292929;
  line-height: 65px;
  text-align: center;
  background-color: transparent;
  margin-top: -5px;
}

header ul.dt-sc-social-icons li a:before {
  opacity: 1;
  -webkit-transition: opacity 0.2s ease-out;
  -moz-transition: opacity 0.2s ease-out;
  transition: opacity 0.2s ease-out;
}

header ul.dt-sc-social-icons li a:hover {
  color: #ffffff;
}

header ul.dt-sc-social-icons li a:hover:before {
  opacity: 0;
}

header ul.dt-sc-social-icons li a:hover span {
  top: 0px;
}

/* header ul.dt-sc-social-icons li a.facebook:before {
  content: "\f09a";
} */

/* header ul.dt-sc-social-icons li a.youtube:before {
  content: "\f167";
} */

/* header ul.dt-sc-social-icons li a.twitter:before {
  content: "\f099";
} */

/* header ul.dt-sc-social-icons li a.gplus:before {
  content: "\f0d5";
} */

header ul.dt-sc-social-icons li a span {
  width: inherit;
  padding: 0px 10px;
  height: 70px;
  line-height: 65px;
  bottom: auto;
  top: -70px;
  left: 0px;
  -webkit-transition: top 0.3s ease-in-out;
  -moz-transition: top 0.3s ease-in-out;
  transition: top 0.3s ease-in-out;
  display: block;
  position: absolute;
}

header ul.dt-sc-social-icons li a.facebook span {
  border-color: #35538f;
  background: #35538f;
}

header ul.dt-sc-social-icons li a.youtube span {
  border-color: #b70400;
  background: #b70400;
}

header ul.dt-sc-social-icons li a.facebook {
  border-color: #35538f;
}

header ul.dt-sc-social-icons li a.youtube {
  border-color: #b70400;
}

header ul.dt-sc-social-icons li a.youtube span {
  border-color: #b70400;
  background: #b70400;
}

header ul.dt-sc-social-icons li a.instagram {
  border-color: #fbad50;
}

header ul.dt-sc-social-icons li a.instagram span {
  border-color: #fbad50;
  background: #fbad50;
}

header ul.dt-sc-social-icons li a.gplus {
  border-color: #d53e2b;
}

header ul.dt-sc-social-icons li a.gplus span {
  border-color: #d53e2b;
  background: #d53e2b;
}

/*--------------------------------------------------------------

3.1 - Menu Styles

--------------------------------------------------------------*/

#menu-container {
  clear: both;
  float: left;
  width: 100%;
  position: relative;
  z-index: 10;
  box-shadow: 0 4px 2px -2px #cccccc;
  -webkit-box-shadow: 0 4px 2px -2px #cccccc;
  -moz-box-shadow: 0 4px 2px -2px #cccccc;
  border-top: 5px solid #ebebeb;
  background: #ffffff;
}

.main-menu-wrapper {
  width: 100%;
  float: left;
  clear: both;
  border-top: 1px solid #dfdfdf;
}

#main-menu {
  /* float: left; */
  margin: 0px;
  padding: 0px;
  position: relative;
  z-index: 100;
  line-height: normal;
}

#main-menu > ul.menu {
  margin: 0px;
  padding: 0px;
}

#main-menu > ul.menu > li {
  margin: 0px;
  padding: 0px;
  margin: 0px;
  display: inline;
  float: left;
  position: relative;
  background: #ffffff;
}

#main-menu > ul.menu > li > a {
  display: block;
  padding: 0px 15px;
  margin: 0px;
  text-transform: uppercase;
  line-height: 65px;
  color: #434343;
  position: relative;
  margin-top: -5px;
  border-top: 5px solid #ebebeb;
}

#main-menu > ul.menu > li:first-child > a {
  padding-left: 10px;
}

#main-menu > ul.menu > li > a:after {
  content: "";
  /* background: -webkit-linear-gradient(bottom, #ffffff -100%, #f3f3f3 89%, #ffffff 100%);
  background: -o-linear-gradient(bottom, #ffffff -100%, #f3f3f3 89%, #ffffff 100%);
  background: -moz-linear-gradient(bottom, #ffffff -100%, #f3f3f3 89%, #ffffff 100%);
  background: -ms-linear-gradient(bottom, #ffffff -100%, #f3f3f3 89%, #ffffff 100%); */
  background: linear-gradient(
    to bottom,
    #ffffff -100%,
    #f3f3f3 89%,
    #ffffff 100%
  );
  width: 2px;
  height: 38px;
  float: left;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

#main-menu > ul.menu > li:last-child > a:after {
  background: none;
  width: 0px;
}

#main-menu > ul.menu > li.red > a {
  border-color: #f27b81;
}

#main-menu > ul.menu > li.current_page_item.red > a,
#main-menu > ul.menu > li.red > a:hover,
#main-menu > ul > li.red:hover > a {
  color: #f27b81;
}

#main-menu > ul.menu > li.mustard > a {
  border-color: #f7c44e;
}

#main-menu > ul.menu > li.current_page_item.mustard > a,
#main-menu > ul.menu > li.mustard > a:hover,
#main-menu > ul > li.mustard:hover > a {
  color: #f7c44e;
}

#main-menu > ul.menu > li.green > a {
  border-color: #aec158;
}

#main-menu > ul.menu > li.current_page_item.green > a,
#main-menu > ul.menu > li.green > a:hover,
#main-menu > ul > li.green:hover > a {
  color: #aec158;
}

#main-menu > ul.menu > li.yellow > a {
  border-color: #fce480;
}

#main-menu > ul.menu > li.current_page_item.yellow > a,
#main-menu > ul.menu > li.yellow > a:hover,
#main-menu > ul > li.yellow:hover > a {
  color: #fce480;
}

#main-menu > ul.menu > li.blue > a {
  border-color: #4cbcd4;
}

#main-menu > ul.menu > li.current_page_item.blue > a,
#main-menu > ul.menu > li.blue > a:hover,
#main-menu > ul > li.blue:hover > a {
  color: #4cbcd4;
}

#main-menu > ul.menu > li.steelblue > a {
  border-color: #4c7297;
}

#main-menu > ul.menu > li.current_page_item.steelblue > a,
#main-menu > ul.menu > li.steelblue > a:hover,
#main-menu > ul > li.steelblue:hover > a {
  color: #4c7297;
}

#main-menu > ul.menu > li.purple > a {
  border-color: #dd72b3;
}

#main-menu > ul.menu > li.current_page_item.purple > a,
#main-menu > ul.menu > li.purple > a:hover,
#main-menu > ul > li.purple:hover > a {
  color: #dd72b3;
}

#main-menu > ul.menu > li.lavender > a {
  border-color: #b0b6fb;
}

#main-menu > ul.menu > li.current_page_item.lavender > a,
#main-menu > ul.menu > li.lavender > a:hover,
#main-menu > ul > li.lavender:hover > a {
  color: #b0b6fb;
}

#main-menu > ul.menu > li.pink > a {
  border-color: #fcc6e6;
}

#main-menu > ul.menu > li.current_page_item.pink > a,
#main-menu > ul.menu > li.pink > a:hover,
#main-menu > ul > li.pink:hover > a {
  color: #fcc6e6;
}

/**Mega Menu**/

#main-menu > ul.menu > li > .megamenu-child-container {
  display: none;
}

.megamenu-child-container {
  background: url(../images/mega_menu_bg.png) center center no-repeat;
  background-color: #ffffff;
  padding: 10px 15px 40px;
  position: absolute;
  top: 100%;
  left: 0px;
  float: left;
  border-radius: 0px 0px 5px 5px;
  -webkit-border-radius: 0px 0px 5px 5px;
  -moz-border-radius: 0px 0px 5px 5px;
  border-top: 3px solid #f8f8f8;
}

.megamenu-2-columns-group .megamenu-child-container {
  width: 500px;
}

.megamenu-3-columns-group .megamenu-child-container {
  width: 750px;
}

.megamenu-4-columns-group .megamenu-child-container {
  width: 1000px;
}

.megamenu-child-container > ul.sub-menu > li {
  width: 230px;
  padding: 15px;
  float: left;
  display: inline;
}

.megamenu-child-container > ul.sub-menu > li.menu-item-with-widget-area {
  width: 280px;
}

.megamenu-2-columns-group
  .megamenu-child-container
  > ul.sub-menu
  > li.menu-item-fullwidth {
  width: 470px;
  display: block;
}

.megamenu-3-columns-group
  .megamenu-child-container
  > ul.sub-menu
  > li.menu-item-fullwidth {
  width: 720px;
  display: block;
}

.megamenu-4-columns-group
  .megamenu-child-container
  > ul.sub-menu
  > li.menu-item-fullwidth {
  width: 970px;
  display: block;
  padding-top: 0px;
  padding-right: 5px;
}

.megamenu-child-container > ul.sub-menu {
  padding: 0px;
  margin: 0px;
}

.megamenu-child-container > ul.sub-menu > li > a,
.megamenu-child-container > ul.sub-menu > li > .nolink-menu {
  color: #343434;
  display: block;
  font-size: 22px;
  background-image: url(../images/megamenu-title_bg.png);
  padding-bottom: 25px;
  margin-bottom: 25px;
  background-repeat: no-repeat;
  background-position: left bottom;
}

.megamenu-child-container > ul.sub-menu > li > a .menu-item-description,
.menu-item-description {
  color: #b1b1b1;
  display: block;
  font-weight: normal;
  padding: 0px;
}

.dt-megamenu-custom-content {
  display: block;
  padding: 10px 0px 0px;
}

.megamenu-child-container ul.sub-menu > li > ul {
  padding: 0px 0px 0px;
}

.megamenu-child-container ul.sub-menu > li > ul li {
  display: block;
  padding-left: 10px;
}

.megamenu-child-container ul.sub-menu > li > ul li a {
  color: #545454;
  position: relative;
  padding: 5px 0px 5px 20px;
  display: block;
  font-weight: 300;
}

.megamenu-child-container ul.sub-menu > li > ul li:last-child a {
  border: none;
}

.megamenu-child-container ul.sub-menu > li > ul > li > ul {
  padding-left: 20px;
}

.megamenu-child-container > ul.sub-menu > li > a:hover,
.megamenu-child-container > ul.sub-menu > li > a:hover .menu-item-description {
  color: #ffffff;
}

.megamenu-child-container
  > ul.sub-menu
  > li.fulwidth-image-link
  > .nolink-menu {
  padding: 0px;
}

.megamenu-child-container > ul.sub-menu > li.fulwidth-image-link ul.sub-menu {
  padding-top: 0px;
}

.megamenu-child-container > ul.sub-menu > li.fulwidth-image-link img {
  max-width: 100%;
}

#main-menu ul li.menu-item-simple-parent ul {
  width: 220px;
  background: #ffffff;
  padding: 10px 0px 10px;
  position: absolute;
  top: 100%;
  left: 0px;
  float: left;
}

#main-menu ul li.menu-item-simple-parent ul li ul {
  left: 220px;
  top: -8px;
  border-top: 0px;
  padding-top: 5px;
}

.megamenu-child-container > ul.sub-menu > li.fulwidth-image-link img {
  max-width: 100%;
}

#main-menu > ul.menu > li.current_page_item > a,
#main-menu > ul.menu > li a:hover,
#main-menu ul.menu ul.sub-menu li.current_page_item > a {
  color: #7d996e;
}

#main-menu ul.menu li.current_page_item.red ul.sub-menu li a:hover,
#main-menu
  ul.menu
  li.current_page_item.red
  ul.sub-menu
  li.current_page_item
  > a {
  color: #f27b81;
}

#main-menu ul.menu li.current_page_item.mustard ul.sub-menu li a:hover,
#main-menu
  ul.menu
  li.current_page_item.mustard
  ul.sub-menu
  li.current_page_item
  > a {
  color: #f7c44e;
}

#main-menu ul.menu li.current_page_item.green ul.sub-menu li a:hover,
#main-menu
  ul.menu
  li.current_page_item.green
  ul.sub-menu
  li.current_page_item
  > a {
  color: #aec158;
}

#main-menu ul.menu li.current_page_item.yellow ul.sub-menu li a:hover,
#main-menu
  ul.menu
  li.current_page_item.yellow
  ul.sub-menu
  li.current_page_item
  > a {
  color: #fce480;
}

#main-menu ul.menu li.current_page_item.blue ul.sub-menu li a:hover,
#main-menu
  ul.menu
  li.current_page_item.blue
  ul.sub-menu
  li.current_page_item
  > a {
  color: #4cbcd4;
}

#main-menu ul.menu li.current_page_item.steelblue ul.sub-menu li a:hover,
#main-menu
  ul.menu
  li.current_page_item.steelblue
  ul.sub-menu
  li.current_page_item
  > a {
  color: #4c7297;
}

#main-menu ul.menu li.current_page_item.lavender ul.sub-menu li a:hover,
#main-menu
  ul.menu
  li.current_page_item.lavender
  ul.sub-menu
  li.current_page_item
  > a {
  color: #b0b6fb;
}

#main-menu ul.menu li.current_page_item.purple ul.sub-menu li a:hover,
#main-menu
  ul.menu
  li.current_page_item.purple
  ul.sub-menu
  li.current_page_item
  > a {
  color: #dd72b3;
}

#main-menu ul.menu li.current_page_item.pink ul.sub-menu li a:hover,
#main-menu
  ul.menu
  li.current_page_item.pink
  ul.sub-menu
  li.current_page_item
  > a {
  color: #fcc6e6;
}

#main-menu .megamenu-child-container .widget ul.product_list_widget li {
  clear: both;
  display: block;
  padding: 15px 0px 30px;
}

#main-menu
  .megamenu-child-container
  .widget
  ul.product_list_widget
  li:first-child {
  padding-top: 0px;
}

#main-menu .megamenu-child-container .widget ul.product_list_widget li a {
  font-weight: 100;
  color: #545454;
}

#main-menu .megamenu-child-container .widget ul.product_list_widget li a img {
  margin-right: 20px;
  float: left;
  border: 2px solid #f3f3f3;
}

#main-menu
  .megamenu-child-container
  .widget
  ul.product_list_widget
  li
  span.amount {
  margin: 20px 0px 0px;
  display: block;
  font-weight: 600;
}

.megamenu-child-container > ul.sub-menu > li.fill-two-columns {
  width: 445px;
  padding-right: 0px;
}

.menu-item-widget-area-container {
  width: 100%;
  margin: 0px;
  padding: 0px;
}

.menu-item-widget-area-container .widget {
  padding: 0px;
}

.menu-item-widget-area-container .widget .entry-thumb {
  border: none;
}

.menu-item-widget-area-container .widget .entry-body p {
  line-height: 26px;
}

.menu-item-widget-area-container .widget .entry-details {
  border-top: 2px solid #ebebeb;
  padding-top: 15px;
}

.menu-item-widget-area-container .widget .entry-details .entry-title h5 {
  margin-bottom: 10px;
}

.menu-item-widget-area-container .widget .entry-details .entry-title h5 a {
  color: #343434;
}

.menu-item-widget-area-container .widget .entry-details p {
  /* display: inline-block; */
  float: left;
  clear: none;
  background: none;
  margin: 0px;
  line-height: inherit;
  padding: 0px;
}

.menu-item-widget-area-container .widget .entry-details p,
.menu-item-widget-area-container .widget .entry-details p a {
  color: #b6b6b6;
}

.menu-item-widget-area-container .widget .entry-details span {
  /* display: inline-block; */
  float: left;
  margin: 0px 5px;
}

.menu-item-widget-area-container .widget .entry-details .date:before,
.menu-item-widget-area-container .widget .entry-details .comments:before {
  background: none;
}

.dt-menu-toggle {
  display: none;
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  padding: 10px 15px;
  cursor: pointer;
}

.dt-menu-toggle-icon {
  background: #ffffff;
  /* display: inline-block; */
  float: right;
  height: 2px;
  margin-top: 10px;
  position: relative;
  text-align: left;
  width: 20px;
}

.dt-menu-expand {
  font-size: 16px !important;
  display: none !important;
  position: absolute !important;
  right: 0px;
  top: 0px;
  line-height: normal;
  padding: 0px !important;
  text-align: center;
  line-height: 50px !important;
  cursor: pointer;
  width: 37px;
  height: 37px;
  font-weight: bold;
  background: none !important;
}

.dt-menu-toggle-icon:before,
.dt-menu-toggle-icon:after {
  content: "";
  position: absolute;
  background: #ffffff;
  height: 2px;
  width: 20px;
  left: 0px;
  top: -5px;
}

.dt-menu-toggle-icon:after {
  top: 5px;
}

.menu-item-widget-area-container .widget.widget_recent_entries {
  width: 200px;
  margin-left: 30px;
  float: left;
}

.menu-item-widget-area-container li.widget.widget_recent_entries:first-child {
  margin-left: 0px;
}

/**Simple Drop Down Menu**/

#main-menu ul li.menu-item-simple-parent ul,
#main-menu ul li.menu-item-simple-parent ul li ul,
#main-menu ul li.menu-item-simple-parent ul li ul li ul {
  display: none;
}

#main-menu ul li.menu-item-simple-parent ul {
  width: 220px;
  padding: 10px 0px 10px;
  position: absolute;
  top: 100%;
  left: 0px;
  float: left;
  border-radius: 0px 0px 5px 5px;
  -webkit-border-radius: 0px 0px 5px 5px;
  -moz-border-radius: 0px 0px 5px 5px;
  border-top: 3px solid #f8f8f8;
}

#main-menu ul li.menu-item-simple-parent ul:before,
.megamenu-child-container:before {
  content: "";
  background: url(../images/sub_menu_bg.jpg) center bottom repeat-x #ffffff;
  position: absolute;
  bottom: 0px;
  height: 3px;
  width: 100%;
  left: 0px;
  border-radius: 0px 0px 5px 5px;
  -webkit-border-radius: 0px 0px 5px 5px;
  -moz-border-radius: 0px 0px 5px 5px;
}

#main-menu ul li.menu-item-simple-parent ul li {
  display: block;
  padding: 0px 15px;
  position: relative;
}

#main-menu ul li.menu-item-simple-parent ul li a {
  color: #2d2d29;
  position: relative;
  padding: 10px 0px 10px 20px;
  font-weight: 300;
  display: block;
}

#main-menu ul li.menu-item-simple-parent ul li a:before,
.megamenu-child-container ul.sub-menu > li > ul li a:before {
  content: "";
  text-decoration: inherit;
  font-size: 14px;
  position: absolute;
  top: 12px;
  left: 0px;
  background: url(../images/flower.png) center center no-repeat;
  width: 14px;
  height: 14px;
}

#main-menu ul li.current_page_item.red ul li a:before {
  background: url(../images/flower_red.png) center center no-repeat;
}

#main-menu ul li.current_page_item.mustard ul li a:before {
  background: url(../images/flower_mustard.png) center center no-repeat;
}

#main-menu ul li.current_page_item.green ul li a:before {
  background: url(../images/flower_green.png) center center no-repeat;
}

#main-menu ul li.current_page_item.yellow ul li a:before {
  background: url(../images/flower_yellow.png) center center no-repeat;
}

#main-menu ul li.current_page_item.blue ul li a:before {
  background: url(../images/flower_blue.png) center center no-repeat;
}

#main-menu ul li.current_page_item.steelblue ul li a:before {
  background: url(../images/flower_steelblue.png) center center no-repeat;
}

#main-menu ul li.current_page_item.purple ul li a:before {
  background: url(../images/flower_purple.png) center center no-repeat;
}

#main-menu ul li.current_page_item.lavender ul li a:before {
  background: url(../images/flower_lavender.png) center center no-repeat;
}

#main-menu ul li.current_page_item.pink ul li a:before {
  background: url(../images/flower_pink.png) center center no-repeat;
}

#main-menu ul li.menu-item-simple-parent ul li a.dt-menu-expand:before,
.megamenu-child-container ul.sub-menu > li > ul li a.dt-menu-expand:before {
  background: none;
}

.megamenu-child-container ul.sub-menu > li > ul li a:before {
  top: 7px;
}

#main-menu ul li.menu-item-simple-parent ul li:last-child > a {
  border: none;
}

#main-menu ul li.menu-item-simple-parent ul li ul {
  left: 220px;
  top: -5px;
  padding-top: 5px;
}

#main-menu ul li.menu-item-simple-parent:last-child ul {
  right: 0px;
  left: inherit;
}

#main-menu ul li.menu-item-simple-parent:last-child ul li ul {
  right: 220px;
  left: inherit;
}

#main-menu ul li.menu-item-simple-parent:hover > ul,
#main-menu ul li.menu-item-simple-parent ul li:hover > ul,
#main-menu ul li.menu-item-simple-parent ul li ul li:hover > ul,
#main-menu > ul.menu > li:hover > .megamenu-child-container {
  display: block;
}

/* hide the link until viewport size is reached */

a.meanmenu-reveal {
  display: none;
}

/* when under viewport size, .mean-container is added to body */

.mean-container .mean-bar {
  padding: 0;
  z-index: 999999;
}

.mean-container a.meanmenu-reveal {
  width: 22px;
  height: 22px;
  padding: 13px 13px 11px 13px;
  position: absolute;
  top: 5px;
  right: 5px !important;
  cursor: pointer;
  color: #ffffff;
  text-decoration: none;
  font-size: 16px;
  text-indent: -9999em;
  line-height: 22px;
  font-size: 1px;
  display: block;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 700;
  z-index: 9999;
}

.mean-container a.meanmenu-reveal span {
  display: block;
  background: #ffffff;
  height: 3px;
  margin-top: 3px;
}

.mean-container .mean-nav {
  float: left;
  width: 100%;
  position: absolute;
  left: 0px;
  margin-top: 10px;
}

.mean-container .mean-nav ul {
  padding: 0;
  margin: 0;
  width: 100%;
  list-style-type: none;
}

.mean-container .mean-nav ul li {
  position: relative;
  float: left;
  padding-bottom: 0;
  width: 100%;
}

.mean-container .mean-nav {
  float: left;
  width: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  margin-top: 56px;
  z-index: 9999;
}

.mean-container .mean-nav ul li a {
  display: block;
  float: left;
  width: 90%;
  padding: 1em 5%;
  margin: 0;
  text-align: left;
  color: #ffffff;
  border-bottom: 1px solid #383838;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  text-decoration: none;
  text-transform: uppercase;
}

.mean-container .mean-nav ul li:last-child a {
  border-bottom: none;
}

.mean-container .mean-nav ul li li a {
  width: 80%;
  padding: 1em 10%;
  border-bottom: 1px solid #f1f1f1;
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
  opacity: 0.75;
  filter: alpha(opacity=75);
  text-shadow: none !important;
  visibility: visible;
}

.mean-container .mean-nav ul li.mean-last a {
  border-bottom: none;
  margin-bottom: 0;
}

.mean-container .mean-nav ul li li li a {
  width: 70%;
  padding: 1em 15%;
}

.mean-container .mean-nav ul li li li li a {
  width: 60%;
  padding: 1em 20%;
}

.mean-container .mean-nav ul li li li li li a {
  width: 50%;
  padding: 1em 25%;
}

.mean-container .mean-nav ul li a:hover {
  background: #252525;
  background: rgba(255, 255, 255, 0.1);
}

.mean-container .mean-nav ul li a.mean-expand {
  width: 26px;
  height: 26px;
  border: none !important;
  padding: 12px !important;
  text-align: center;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
  font-weight: 700;
}

.mean-container .mean-nav ul li a.mean-expand:hover {
  background: none;
}

.mean-container .mean-push {
  float: left;
  width: 100%;
  padding: 0;
  margin: 0;
  clear: both;
}

.mean-nav .wrapper {
  width: 100%;
  padding: 0;
  margin: 0;
}

/* Fix for box sizing on Foundation Framework etc. */

.mean-container .mean-bar,
.mean-container .mean-bar * {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

header #menu-container ul.dt-sc-social-icons {
  /* float: right; */
  height: 60px;
}

.class_hours ul.dt-sc-social-icons li a:hover {
  opacity: 0.8;
}

.wrapper,
.main #main {
  float: left;
  clear: both;
  margin: 0px;
  padding: 0px 0px 0px;
  width: 100%;
}

/*--------------------------------------------------------------

4.0 - Main Styles

--------------------------------------------------------------*/

#main {
  float: left;
  clear: both;
  margin: 0px;
  padding: 0px 0px 0px;
  width: 100%;
}

#primary.content-full-width {
  width: 100%;
}

#primary {
  margin: 80px 0px 0px;
  padding: 0px;
  float: left;
}

#primary.with-sidebar {
  width: 910px;
  margin-right: 40px;
}

#secondary {
  width: 220px;
  margin: 80px 0px 0px 0px;
  float: left;
  padding: 0px;
}

#main .dt-sc-hr-green-title {
  text-align: center;
  width: 100%;
  background: url(../images/green_title_bg.png) center bottom no-repeat;
  display: inline-block;
  padding: 0px 0px 30px;
  margin: 0px 0px 30px;
  line-height: inherit;
}

#main .dt-sc-hr-white-title {
  text-align: center;
  color: #ffffff;
  width: 100%;
  background: url(../images/white_title_bg.png) center bottom no-repeat;
  display: inline-block;
  padding: 0px 0px 30px;
  margin: 0px 0px 30px;
  line-height: inherit;
}

#main .fullwidth-background.product_bg .dt-sc-hr-white-title {
  color: inherit;
}

#slider {
  float: left;
  clear: both;
  width: 100%;
  margin: 0px;
  padding: 0px;
  position: relative;
  overflow: hidden;
}

#slider img {
  max-width: 100%;
}

/*--------------------------------------------------------------

4.1 - Breadcrumb Styles

--------------------------------------------------------------*/

.breadcrumb-section {
  background: #73d5f0;
  width: 100%;
  float: left;
  clear: both;
  padding: 35px 0px;
  margin: 0px 0px 0px;
}

.breadcrumb-section h1 {
  float: left;
  color: #333334;
  text-transform: capitalize;
  margin-bottom: 0px;
  line-height: normal;
}

.breadcrumb-section .breadcrumb a {
  float: left;
}

.breadcrumb-section .breadcrumb span {
  float: left;
  margin: 0px 8px;
  line-height: 24px;
}

.breadcrumb-section .breadcrumb span.current {
  margin: 0px;
}

.breadcrumb-section .breadcrumb {
  float: right;
  margin: 8px 0px 0px;
}

/**fullwidth-background**/

.fullwidth-background {
  float: left;
  clear: both;
  width: 100%;
  margin: 0px;
  padding: 78px 0px;
  background-image: url(../images/cloud-bg.png);
  background-position: center center;
  background-repeat: repeat;
  background-attachment: fixed;
}

.fullwidth-background.turquoise-bg h2 {
  color: #ffffff;
  text-transform: capitalize;
}

.fullwidth-background.turquoise-bg {
  background-color: #008c99;
}

.fullwidth-background.orange-bg {
  background-color: #cf571b;
}

.fullwidth-background.mustard-bg {
  background: #df9b00;
  width: 100%;
  padding: 78px 0px;
  float: left;
  clear: both;
}

.fullwidth-background.turquoise-plain-bg {
  width: 100%;
  padding: 78px 0px;
  float: left;
  clear: both;
}

.fullwidth-background.product_bg {
  background: url(../images/product_bg.png) center center repeat #d2d2d2;
}

/*--------------------------------------------------------------

4.2 - Add Slider Styles

--------------------------------------------------------------*/

.add-slider-wrapper {
  background-image: url(../images/add_bg.png);
  padding: 7% 11% 2% 7%;
  background-size: contain;
  background-repeat: no-repeat;
}

.add-slider-wrapper iframe {
  overflow: hidden;
}

.fullwidth_banner {
  background: url(../images/services_banner.jpg) center center no-repeat;
  text-align: center;
  width: 100%;
  background-size: cover;
  padding: 87px 0px;
}

.fullwidth_banner h1 {
  font-family: "Berkshire Swash", cursive;
  font-size: 36px;
  margin-bottom: 0px;
}

.add-slider-wrapper .bx-controls.bx-has-pager {
  margin: 54px 0px 0px 0px;
}

.add-slider-wrapper .bx-pager.bx-default-pager {
  width: 100%;
  text-align: center;
}

.add-slider-wrapper .bx-pager-item {
  text-align: center;
  display: inline-block;
}

.add-slider-wrapper .bx-pager-item {
  display: inline-block;
}

.add-slider-wrapper
  .bx-controls.bx-has-pager.bx-has-controls-direction
  .bx-controls-direction {
  display: none;
}

.add-slider-wrapper .bx-pager-item a {
  display: inline-block;
  text-indent: -9999px;
  margin: 0px 8px;
  background: #ffffff;
  width: 14px;
  height: 14px;
  border-radius: 100%;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
}

.add-slider-wrapper .bx-pager-item a.active,
.add-slider-wrapper .bx-pager-item a:hover {
  background: #015057;
}

.about-slider-wrapper {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px;
  position: relative;
}

.about-slider-wrapper ul {
  margin: 0px;
  padding: 0px;
}

.about-slider-wrapper ul li {
  padding: 0px;
}

.about-slider-wrapper .bx-pager.bx-default-pager {
  display: none;
}

.about-slider-wrapper .bx-controls-direction a {
  position: absolute;
  z-index: 9;
  top: 0;
  bottom: 0;
  margin: auto;
  background: url(../images/arrow.png) 0px 0px no-repeat;
  text-indent: -9999px;
  width: 36px;
  height: 36px;
}

.about-slider-wrapper .bx-controls-direction a.bx-prev {
  left: 10px;
  background-position: left top;
}

.about-slider-wrapper .bx-controls-direction a:hover {
  opacity: 0.6;
}

.about-slider-wrapper .bx-controls-direction a.bx-next {
  background-position: right top;
  right: 10px;
}

/*--------------------------------------------------------------

5.0 - Portfolio Styles 

--------------------------------------------------------------*/

.portfolio-content {
  background-image: url(../images/hexagon.png);
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  width: 381px;
  height: 330px;
  position: relative;
  overflow: hidden;
  z-index: 9;
}

.portfolio-content1 {
  margin: auto;
  display: block;
  right: 0px;
  left: 0px;
}

.portfolio-content2 {
  margin: -168px 0 0 176px;
  float: left;
}

.portfolio-content3 {
  margin: -168px 0px 0px 182px;
  float: left;
}

.portfolio-content4 {
  margin: -6px 0px 0px 176px;
  float: left;
}

.portfolio-content5 {
  margin: -168px 0px 0px -100px;
  float: left;
}

.portfolio-content6 {
  margin: -6px 0px 0px -100px;
  float: left;
}

.portfolio-content7 {
  margin: -168px 0px 0px 457px;
  float: left;
}

.front-portfolio {
  overflow: hidden;
  position: absolute;
  display: inline-block;
  height: 318px;
  left: 0;
  top: 0;
  transform: rotate(120deg);
  -webkit-transform: rotate(120deg);
  -moz-transform: rotate(120deg);
  -ms-transform: rotate(120deg);
  right: 0;
  bottom: 0;

  margin: auto;
}

.portfolio-outer {
  overflow: hidden;
  width: 100%;
  height: 100%;
  transform: rotate(-60deg);
  -moz-transform: rotate(-60deg);
  -ms-transform: rotate(-60deg);
  -webkit-transform: rotate(-60deg);
}

.front-portfolio .portfolio-thumb {
  overflow: hidden;
  width: 100%;
  height: 100%;
  transform: rotate(-60deg);
  -moz-transform: rotate(-60deg);
  -ms-transform: rotate(-60deg);
  -webkit-transform: rotate(-60deg);
}

.front-portfolio .image-overlay {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: rgba(0, 140, 153, 0.9);
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  /* Safari only override */

  ::i-block-chrome,
  .front-portfolio .image-overlay {
    visibility: visible;
    opacity: 1;
    display: none;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
  /* Safari only override */

  ::i-block-chrome,
  .front-portfolio:hover .image-overlay {
    display: block;
  }
}

.portfolio-content1 .front-portfolio .image-overlay {
  background: rgba(230, 188, 107, 0.9);
}

.portfolio-content2 .front-portfolio .image-overlay {
  background: rgba(243, 166, 189, 0.9);
}

.portfolio-content3 .front-portfolio .image-overlay {
  background: rgba(230, 107, 114, 0.9);
}

.portfolio-content4 .front-portfolio .image-overlay {
  background: rgba(233, 186, 11, 0.9);
}

.portfolio-content5 .front-portfolio .image-overlay {
  background: rgba(47, 134, 206, 0.9);
}

.portfolio-content6 .front-portfolio .image-overlay {
  background: rgba(229, 104, 84, 0.9);
}

.portfolio-content7 .front-portfolio .image-overlay {
  background: rgba(103, 156, 187, 0.9);
}

.portfolio-content .front-portfolio .image-overlay h5 a {
  color: inherit;
}

.portfolio-content1 .front-portfolio .image-overlay a {
  color: #ddaa0c;
}

.portfolio-content2 .front-portfolio .image-overlay a {
  color: #fa8cba;
}

.portfolio-content3 .front-portfolio .image-overlay a {
  color: #ef7b80;
}

.portfolio-content4 .front-portfolio .image-overlay a {
  color: #ebb50d;
}

.portfolio-content5 .front-portfolio .image-overlay a {
  color: #6996cf;
}

.portfolio-content6 .front-portfolio .image-overlay a {
  color: #ec6d5a;
}

.portfolio-content7 .front-portfolio .image-overlay a {
  color: #6a9eba;
}

.portfolio-content .front-portfolio .image-overlay a:hover {
  color: #333338;
}

.portfolio-thumb:hover .image-overlay {
  visibility: visible;
  opacity: 1;
}

.front-portfolio.even {
  margin-top: 102px;
}

.front-portfolio-container {
  float: left;
  clear: both;
  width: 100%;
  margin: 0px auto;
  padding: 0px;
  position: relative;
}

.front-portfolio-container .front-portfolio.center.top {
  top: 0px;
  left: 0px;
  right: 0px;
  margin: 5px auto 0px;
}

.front-portfolio.top.left {
  top: 105px;
  left: 295px;
}

.front-portfolio.bottom.left {
  top: 310px;
  left: 295px;
}

.front-portfolio.center.center {
  top: 205px;
  left: 470px;
}

.front-portfolio.center.bottom {
  top: 410px;
  left: 475px;
}

.front-portfolio.top.right {
  top: 100px;
  right: 345px;
}

.front-portfolio.bottom.right {
  top: 305px;
  right: 340px;
}

.front-portfolio .image-overlay h5 {
  top: 35%;
  left: 0px;
  right: 0px;
  margin: auto;
  text-align: center;
  position: absolute;
  text-transform: uppercase;
  color: #fff;
  font-weight: 700;
  transform: scale(0);
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
}

.front-portfolio .image-overlay h5 a {
  color: #ffffff;
}

.front-portfolio .image-overlay a.link,
.front-portfolio .image-overlay a.zoom {
  background: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 18px;
  height: 42px;
  line-height: 42px;
  width: 25px;
  text-align: center;
  position: absolute;
  z-index: 9999;
}

.front-portfolio .image-overlay .link {
  top: 55%;
  margin: -23px 0px 0px;
  left: 0px;
}

.front-portfolio .image-overlay .zoom {
  top: 55%;
  margin: -23px 0px 0px;
  right: 0px;
}

.front-portfolio:hover .link {
  left: 35%;
}

.front-portfolio:hover .zoom {
  right: 35%;
}

.front-portfolio:hover .image-overlay h5 {
  transform: scale(1);
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
}

.front-portfolio a.link,
.front-portfolio a.zoom {
  width: 25px;
  height: 42px;
  background: #ffffff;
  position: absolute;
}

.front-portfolio a.link:before,
.front-portfolio a.zoom:before {
  content: "";
  position: absolute;
  left: -14px;
  width: 0px;
  height: 0px;
  border-top: 21px solid transparent;
  border-bottom: 21px solid transparent;
  border-right: 14px solid #ffffff;
}

.front-portfolio a.link:after,
.front-portfolio a.zoom:after {
  content: "";
  position: absolute;
  right: -14px;
  width: 0px;
  height: 0px;
  border-top: 21px solid transparent;
  border-bottom: 21px solid transparent;
  border-left: 14px solid #ffffff;
}

.front-portfolio-container .carousel-arrows {
  width: 100%;
  display: inline-block;
  text-align: center;
  margin: 50px auto 0;
}

.front-portfolio-container .carousel-arrows a {
  font-size: 24px;
  color: #898b8c;
  width: 50px;
  height: 50px;
  line-height: 44px;
  margin: 0px 14px;
  display: inline-block;
  border: 2px solid #898b8c;
  border-radius: 100%;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
}

.front-portfolio-container .carousel-arrows a:hover {
  border: 2px solid #000000;
}

.front-portfolio-container .carousel-arrows a.front-portfolio-prev span {
  margin-left: -1px;
}

/**activity styles**/

.activity {
  float: left;
  width: 100%;
  padding: 20px 0px 10px;
  background: #efc043;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-top: 5px solid #df9b00;
  border-bottom: 5px solid #df9b00;
}

.activity h4 {
  text-align: center;
}

.activity p {
  padding: 0px 20px;
}

.activity.box1 {
  background: #efc043;
  border-color: #df9b00;
}

.activity.box2 {
  background: #d5c651;
  border-color: #c2b130;
}

.activity.box3 {
  background: #f5bbd1;
  border-color: #de81b8;
}

.activity.box4 {
  background: #a9e1f9;
  border-color: #5fa9c9;
}

.activity img {
  display: block;
  margin-bottom: 15px;
}

/**sorting-container**/

.dt-sc-sorting-container {
  clear: both;
  display: inline-block;
  text-align: center;
  margin: 0px auto 45px;
  padding: 0px;
  width: 100%;
}

.dt-sc-sorting-container a {
  background: #df9b00;
  padding: 8px 12px;
  display: inline-block;
  margin: 0px 5px 0px 0px;
  border-bottom: 3px solid #ca8e06;
  border-radius: 8px 0px 8px 0px;
  -webkit-border-radius: 8px 0px 8px 0px;
  -moz-border-radius: 8px 0px 8px 0px;
  color: #ffffff;
}

.dt-sc-sorting-container a:hover,
.dt-sc-sorting-container .active-sort {
  color: #ffffff;
  background: #cf571b;
  border-bottom: 3px solid #b54913;
}

.portfolio-detail {
  display: block;
}

.isotope-hidden {
  display: none !important;
}

.isotope,
.isotope .isotope-item {
  -webkit-transition-duration: 0.8s;

  -moz-transition-duration: 0.8s;

  -ms-transition-duration: 0.8s;

  -o-transition-duration: 0.8s;

  transition-duration: 0.8s;
}

.isotope {
  -webkit-transition-property: height, width;

  -moz-transition-property: height, width;

  -ms-transition-property: height, width;

  -o-transition-property: height, width;

  transition-property: height, width;
}

.isotope .isotope-item {
  -webkit-transition-property: -webkit-transform, opacity;

  -moz-transition-property: -moz-transform, opacity;

  -ms-transition-property: -ms-transform, opacity;

  -o-transition-property: top, left, opacity;

  transition-property: transform, opacity;
}

.isotope.no-transition,
.isotope.no-transition .isotope-item,
.isotope .isotope-item.no-transition {
  -webkit-transition-duration: 0s;

  -moz-transition-duration: 0s;

  -ms-transition-duration: 0s;

  -o-transition-duration: 0s;

  transition-duration: 0s;
}

.dt-sc-portfolio-container {
  clear: both;
}

#primary.content-full-width
  .dt-sc-portfolio-container
  .portfolio.dt-sc-one-fourth {
  width: 280px;
}

#primary.content-full-width
  .dt-sc-portfolio-container
  .portfolio.dt-sc-one-third {
  width: 379px;
  text-align: center;
}

.portfolio.dt-sc-one-fourth {
  text-align: center;
}

.dt-sc-portfolio-container .portfolio {
  margin: 0px 0px 30px 0px;
}

.portfolio.dt-sc-one-third {
  width: 292px;
  margin: 0px 0px 30px 0px;
  text-align: center;
}

.portfolio-thumb {
  width: 100%;
  position: relative;
  display: inline-block;
  float: none;
  margin: 0px 0px 20px;
  overflow: hidden;
}

.portfolio.dt-sc-one-fourth .portfolio-thumb {
  max-height: 242px;
}

.content-full-width .portfolio.dt-sc-one-third .portfolio-thumb {
  max-height: 328px;
}

.portfolio.dt-sc-one-third .portfolio-thumb {
  max-height: 251px;
}

.portfolio.dt-sc-one-fourth .portfolio-thumb img,
.portfolio.dt-sc-one-third .portfolio-thumb img {
  height: auto;
}

.portfolio.dt-sc-one-fourth .portfolio-thumb {
  max-height: 242px;
}

.portfolio-detail h5 {
  margin: 0px 0px 10px;
}

.portfolio-detail p:before {
  content: "\f06b";
  display: inline-block;
  margin: 0px 5px 0px 0px;
}

/**portfolio detail styles**/

.portfolio-single {
  float: left;
  width: 100%;
  padding: 0px;
  margin: 0px;
}

.portfolio-slider-container {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px;
  position: relative;
}

.portfolio-slider-container .bx-controls-direction a {
  position: absolute;
  z-index: 9;
  top: 0;
  bottom: 0;
  margin: auto;
  text-indent: -9999px;
  width: 40px;
  height: 60px;
}

.portfolio-slider-container .bx-controls-direction a:hover {
  opacity: 0.8;
}

.portfolio-slider-container .bx-controls-direction a.bx-prev {
  background: url(../images/portfolio-slider-arrow-left.png) center center
    no-repeat #008c99;
  left: 0px;
}

.portfolio-slider-container .bx-controls-direction a.bx-next {
  background: url(../images/portfolio-slider-arrow-right.png) center center
    no-repeat #008c99;
  right: 0px;
}

.portfolio-slider-container .bx-pager.bx-default-pager .bx-pager-item a {
  display: none;
}

.project-details {
  float: left;
  clear: both;
  width: 100%;
  margin: 25px 0px 0px;
  padding: 30px 0px 0px;
  border-top: 1px dotted #a2a2a2;
}

.project-details p {
  margin: 0px 0px 15px;
}

.project-details p span {
  float: left;
  /* display: inline-block; */
  margin: 0px 5px 0px 0px;
  line-height: inherit;
}

/*--------------------------------------------------------------

6.0 - Team Styles

--------------------------------------------------------------*/

.dt-sc-team {
  float: left;
  width: 100%;
}

.dt-sc-team .dt-sc-entry-thumb {
  position: relative;
  float: left;
  overflow: hidden;
}

.dt-sc-team .item-mask,
.services .item-mask {
  left: 0px;
  position: absolute;
  top: 0px;
  transition: all 0.2s ease 0s;
  z-index: 999;
}

.dt-sc-team .dt-sc-entry-thumb img {
  float: left;
  width: 100%;
}

.dt-sc-team .dt-sc-image-overlay,
.portfolio .image-overlay {
  background: rgba(24, 151, 163, 0.9);
  float: left;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  transition: opacity 0.2s ease 0s;
  -moz-transition: opacity 0.2s ease 0s;
  -webkit-transition: opacity 0.2s ease 0s;
  visibility: hidden;
  width: 100%;
}

.dt-sc-team:hover .dt-sc-entry-thumb .dt-sc-image-overlay,
.portfolio:hover .image-overlay {
  opacity: 1;
  visibility: visible;
}

.dt-sc-entry-title {
  padding: 15px 0px 11px;
  text-align: center;
  float: left;
  width: 100%;
}

.dt-sc-entry-title h2,
.dt-sc-entry-title h6 {
  margin: 0 0 15px;
}

.dt-sc-team:hover .dt-sc-entry-title span {
  background: #e2b961;
}

.dt-sc-team:hover .dt-sc-image-overlay {
  opacity: 1;
  visibility: visible;
}

.dt-sc-image-overlay a.link,
.portfolio .image-overlay a.link {
  left: 0px;
}

.dt-sc-image-overlay a.zoom,
.portfolio .image-overlay a.zoom {
  right: 0px;
}

.dt-sc-team:hover .dt-sc-image-overlay a.link,
.portfolio:hover .image-overlay a.link {
  left: 29%;
}

.dt-sc-team:hover .dt-sc-image-overlay a.zoom,
.portfolio:hover .image-overlay a.zoom {
  right: 29%;
}

.content-full-width .portfolio.dt-sc-one-third:hover .image-overlay a.link {
  left: 35%;
}

.content-full-width .portfolio.dt-sc-one-third:hover .image-overlay a.zoom {
  right: 35%;
}

.dt-sc-image-overlay a,
.portfolio .image-overlay a {
  display: inline-block;
  position: absolute;
  text-align: center;
  z-index: 9;
  background: #ffffff;
  width: 25px;
  height: 40px;
  line-height: 40px;
  top: 50%;
  margin: -18px 0px 0px;
}

.dt-sc-image-overlay a:before,
.portfolio .image-overlay a:before {
  content: "";
  position: absolute;
  left: -12px;
  width: 0px;
  height: 0px;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-right: 12px solid #ffffff;
}

.dt-sc-image-overlay a:after,
.portfolio .image-overlay a:after {
  content: "";
  position: absolute;
  right: -12px;
  width: 0px;
  height: 0px;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-left: 12px solid #ffffff;
}

/*--------------------------------------------------------------

7.0 - Blog Styles

--------------------------------------------------------------*/

.blog-entry {
  width: 100%;
  margin: 0px 0px 50px;
  padding: 0px 0px 0px;
  float: left;
  clear: both;
  position: relative;
}

.blog-entry-inner {
  padding: 0px 0px 0px 122px;
}

.entry-meta {
  width: 130px;
  text-align: center;
  position: absolute;
  left: 0;
}

.entry-meta .entry_format {
  display: inline-block;
  text-align: center;
  text-decoration: inherit;
  position: relative;
}

.entry-meta .date {
  text-align: left;
  line-height: normal;
  width: auto;
}

.entry-meta .date:after,
.entry-meta .comments:after,
.entry-meta .entry_format:after {
  content: "";
  border-style: solid;
  border-width: 5px 8px 0px 0px;
  border-color: #008c99 transparent transparent;
  width: 0;
  height: 0;
  line-height: 0;
  font-size: 0px;
  position: absolute;
  right: 0px;
  bottom: -5px;
}

.entry-meta .date p {
  line-height: 15px;
  font-size: 12px;
  margin: 4px 0px 0px 8px;
  display: inline-block;
}

.entry-meta .date span {
  display: inline-block;
  line-height: 36px;
}

.entry-meta .comments {
  display: block;
}

.entry-meta .blog-author {
  max-width: 56px;
  max-height: 56px;
  border-radius: 100%;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  border: 3px solid #008c99;
  background: #a2bc56;
  display: inline-block;
  margin-bottom: 15px;
}

.entry-meta .blog-author img {
  border-radius: 100%;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
}

.entry-meta .blog-author:hover {
  border: 3px solid #333334;
}

.entry-details p {
  line-height: 25px;
}

.date,
.comments,
.entry_format {
  margin: 0px 0px 10px 0px;
  float: right;
  color: #ffffff;
  display: block;
  padding: 0px 18px 0px 16px;
  line-height: 39px;
  position: relative;
  clear: both;
}

.date p,
.date span {
  color: #ffffff;
}

.date span {
  font-size: 26px;
  font-weight: bold;
}

.date:before,
.comments:before,
.entry_format:before {
  content: "";
  background: url(../images/green-meta-arrow.png) left center no-repeat;
  float: left;
  width: 15px;
  height: 39px;
  margin: 0px 0px 0px 0px;
  position: absolute;

  top: 0px;
  left: -15px;
}

.entry-thumb {
  border: 5px solid #008c99;
  margin-bottom: 30px;
  display: block;
}

.entry-thumb img {
  display: block;
}

#primary.with-sidebar .dt-sc-one-half .entry-meta .blog-author {
  max-width: 50px;
  max-height: 50px;
  margin-bottom: 10px;
}

#primary.with-sidebar .dt-sc-one-half .date,
#primary.with-sidebar .dt-sc-one-half .comments,
#primary.with-sidebar .dt-sc-one-half .entry_format {
  margin: 0px 0px 6px 14px;
}

/*--------------------------------------------------------------

7.1 - Blog Comments Styles

--------------------------------------------------------------*/

.commententries {
  width: 100%;
  float: left;
  margin: 10px 0px 0px;
  padding: 0px;
}

ul.commentlist {
  float: left;
  margin: 0px 0px 20px 0px;
  padding: 0px 0px 20px 0px;
  list-style: none;
  border-top: 0px;
}

ul.commentlist li {
  display: block;
  float: none;
  list-style: none;
  border: none;
  margin: 0px 0px 28px;
  padding: 0px 0px 0px 110px;
  position: relative;
  clear: both;
  border-bottom: 2px dotted #a2a2a2;
}

ul.commentlist li ul.children {
  margin: 30px 0px 0px 0px;
  padding: 0px;
  float: left;
  border: none;
  clear: both;
  width: 100%;
}

ul.commentlist li .respond {
  margin-left: 69px;
}

ul.commentlist li .comment-author {
  left: 0px;
  top: 0px;
  position: absolute;
  background: none;
  float: none;
  width: auto;
  border: none;
  z-index: 1;
}

ul.commentlist li .comment-author img {
  max-width: 100px;
  max-height: 100px;
  float: left;
  margin: 0px;
  -moz-transition: all 1s ease;
  -webkit-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}

ul.commentlist li .author-name {
  font-size: 15px;
  padding-bottom: 5px;
}

ul.commentlist li .commentmetadata {
  font-size: 13px;
  padding-bottom: 5px;
  font-style: italic;
}

ul.commentlist li .comment-details {
  display: block;
}

ul.commentlist li .comment-body {
  margin: 0px;
  padding: 0px;
}

ul.commentlist li .comment-content {
  padding: 5px 10px 10px 0px;
  margin: 0px 0px 0px;
}

ul.commentlist li .reply {
  background: #f0f3f3;
  margin: -10px 0px 0px;
  float: right;
  width: 48px;
  text-align: right;
}

ul.commentlist li .reply a {
  font-size: 13px;
}

ul.commentlist li ul.children {
  width: 100%;
  float: left;
  clear: both;
  margin: 20px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
}

/**.fullwidth-background.orange-bg styles**/

.fullwidth-background .blog-entry {
  margin: 0px 0px 0px;
}

.fullwidth-background.orange-bg .date:before,
.fullwidth-background.orange-bg .comments:before,
.fullwidth-background.orange-bg .entry_format:before {
  content: "";
  background: url(../images/meta_arrow.png) left center no-repeat;
  float: left;
  width: 15px;
  height: 39px;
  margin: 0px 0px 0px 0px;
  position: absolute;
  top: 0px;
  left: -15px;
}

.fullwidth-background.orange-bg .entry-title h3 a {
  color: #fff;
}

.fullwidth-background.orange-bg .date,
.fullwidth-background.orange-bg .comments,
.fullwidth-background.orange-bg .entry_format {
  background: #fff;
}

.fullwidth-background.orange-bg .entry-details p {
  color: #fff;
  font-size: 16px;
  line-height: 30px;
}

.fullwidth-background.orange-bg .entry-title h3 a:hover {
  color: #a2bc56;
}

.fullwidth-background.orange-bg a,
.fullwidth-background.orange-bg .date p,
.fullwidth-background.orange-bg .date span {
  color: #cf571c;
}

.fullwidth-background.orange-bg .entry-meta .date:after,
.fullwidth-background.orange-bg .entry-meta .comments:after,
.fullwidth-background.orange-bg .entry-meta .entry_format:after {
  border-color: #ffffff transparent transparent;
}

.fullwidth-background.orange-bg .entry-meta .blog-author:hover {
  border: 3px solid #ffffff;
}

.fullwidth-background.orange-bg .entry-thumb {
  border: 3px solid #ffffff;
  margin-bottom: 30px;
  display: block;
}

.fullwidth-background.orange-bg .entry-meta .blog-author {
  border: 3px solid #ffffff;
}

.fullwidth-background.orange-bg .entry-meta .blog-author:hover {
  border: 3px solid #a2bc56;
}

.fullwidth-background.orange-bg .dt-sc-button.small {
  font-size: 13px;
  background: #ffffff;
  color: #cf571c;
}

.fullwidth-background.orange-bg .dt-sc-button.small:hover {
  background: #a2bc56;
  color: #fff;
}

/*--------------------------------------------------------------

8.0 - Shop Page Styles

--------------------------------------------------------------*/

ul.products {
  float: left;
  clear: both;
  margin: 0px;
  padding: 0px;
  width: 100%;
}

ul.products li {
  background: #fff;
  padding-bottom: 0px;
  float: left;
  /* display: inline-block; */
  margin-bottom: 2%;
}

ul.products li.dt-sc-one-third.column:nth-child(3n + 1),
ul.products li.dt-sc-one-fourth.column:nth-child(4n + 1) {
  clear: both;
}

.fullwidth-background ul.products li {
  background: #fff;
  padding-bottom: 0px;
  width: 270px;
  margin: 0px 0px 0px 30px;
}

.fullwidth-background ul.products li.column.first {
  margin-left: 0px;
}

.product-thumb {
  border-bottom: 1px solid #f2f2f2;
  position: relative;
}

.product-thumb .sale {
  position: absolute;
  left: 25px;
  top: 10px;
  z-index: 9;
  font-size: 16px;
  color: #fff;
  background: #008c99;
  width: 34px;
  height: 50px;
  line-height: 50px;
  float: left;
}

.product-thumb .sale:after {
  width: 0;
  content: "";
  height: 0;
  border-top: 25px solid transparent;
  border-bottom: 25px solid transparent;
  border-left: 13px solid #008c99;
  float: left;
  position: absolute;
  top: 0px;
  right: -13px;
}

.product-thumb .sale:before {
  width: 0;
  content: "";
  height: 0;
  border-top: 25px solid transparent;
  border-bottom: 25px solid transparent;
  border-right: 13px solid #008c99;
  float: left;
  position: absolute;
  top: 0px;
  left: -13px;
}

.product-thumb a img {
  display: block;
}

.product-details {
  padding: 20px 20px;
  float: left;
  width: 100%;
}

.product-details h5 {
  float: left;
  margin-bottom: 0px;
}

.product-details h5 a {
  color: #8c8c8c;
  font-size: 14px;
  font-weight: 100;
}

.product-details h5 a:hover {
  color: #008c99;
}

.product-details .price {
  float: right;
  font-weight: bold;
  color: #008c99;
}

.product-thumb .product-button {
  position: absolute;
  display: inline-block;
  top: 50%;
  left: 0px;
  right: 0px;
  margin: -11px auto 0px;
  display: none;
  text-align: center;
}

.product-thumb .product-button a {
  padding: 13px 18px;
  border: 1px solid #fff;
  color: #fff;
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
}

.product-thumb .product-button a:hover {
  color: #008c99;
  border: 1px solid #008c99;
  background: #fff;
}

ul.products li:hover .product-details h5 a {
  color: #008c99;
}

ul.products li:hover .product-thumb .product-button {
  display: block;
}

ul.products .image-overlay {
  background: rgba(0, 140, 153, 0.5);
  opacity: 0;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  position: absolute;
  z-index: 9;
}

ul.products li:hover .image-overlay {
  opacity: 1;
}

.pagination {
  float: left;
  width: 100%;
  margin: 20px 0px 10px;
  padding: 0px;
}

.pagination ul {
  float: left;
  margin: 0px;
  padding: 0px;
}

.pagination ul li {
  float: left;
  display: inline;
  margin: 0px;
  padding: 0px;
}

.pagination ul li a,
.pagination .next-post a,
.pagination .prev-post a {
  font-size: 14px;
  font-weight: bold;
  line-height: 30px;
  padding: 0px 12px;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  margin: 0px 10px 0px 0px;
  float: left;
  border: 1px solid #008c99;
  position: relative;
  z-index: 1;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
  -o-transition: all 0.3s;
  -ms-transition: all 0.3s;
}

.pagination ul li.active-page {
  font-weight: bold;
  line-height: 30px;
  padding: 0px 12px;
  margin: 0px 10px 0px 0px;
  float: left;
  position: relative;
  border: 1px solid;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
}

.pagination ul li a:hover,
.pagination .next-post a:hover,
.pagination .prev-post a:hover {
  color: #ffffff;
}

.pagination .next-post {
  float: left;
  margin-left: 4px;
  padding: 0px;
}

.pagination .prev-post {
  float: left;
  margin-right: 4px;
  padding: 0px;
}

.images {
  float: left;
  width: 32%;
  margin-right: 25px;
}

.images img {
  border: 3px solid #008c99;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
}

.summary {
  float: left;
  width: 64%;
}

.summary h2,
.summary p.price {
  display: block;
  float: left;
  width: 100%;
}

.summary p.price {
  margin-bottom: 15px;
}

.summary p.price span {
  font-size: 24px;
  line-height: 20px;
  margin: 0px 0px 0px 5px;
  float: left;
}

.summary p.price del {
  float: left;
}

ol.commentlist {
  padding-left: 0px;
}

form.cart input[type="button"] {
  padding: 11px 15px;
  width: 40px;
  height: 40px;
  color: #ffffff;
  font-weight: bold;
  font-size: 15px;
  float: left;
  /* display: inline-block; */
}

form.cart input[type="number"] {
  /* display: inline-block; */
  width: 55px;
  height: 40px;
  text-align: center;
  color: #333334;
  border: 0px;
  margin: 0px 0px 0px;
  padding: 0px;
  float: left;
}

form.cart input[type="text"] {
  width: 55px;
  height: 40px;
  float: left;
  padding: 0px;
  line-height: 40px;
  text-align: center;
}

form.cart .quantity.buttons_added {
  margin: 10px 0px 12px;
  /* display: inline-block; */
  width: 100%;
  float: left;
}

form.cart button[type="submit"] {
  text-transform: uppercase;
  font-weight: bold;
}

.summary .product_meta {
  margin: 15px 0px 0px;
  float: left;
  clear: both;
}

.related {
  width: 100%;
  clear: both;
  float: left;
  margin: 40px 0px 0px;
}

ol.commentlist {
  list-style: none;
}

ol.commentlist .comment_container {
  float: left;
  margin-right: 20px;
  max-width: 60px;
}

/**events**/

.events-list {
  width: 100%;
  float: left;
  margin: 0px 0px 30px;
  padding: 0px 0px 20px;
  border-bottom: 1px solid #d8e8ea;
}

.column .events-list:last-child {
  border: none;
}

.events-list .event-thumb {
  float: left;
  margin: 0px 20px 0px 0px;
  max-width: 100px;
}

.events-list .event-thumb img {
  max-width: 100%;
}

.events-list h2 {
  font-size: 16px;
  margin-bottom: 8px;
  display: block;
}

.events-list h2 a {
  color: #000000;
  display: inherit;
}

.events-list .event-meta {
  margin: 0px;
  color: #5c5c5c;
  padding: 0px 0px 10px;
  display: block;
  width: 100%;
  font-size: 16px;
}

.events-list .event-meta span {
  margin-right: 5px;
  position: relative;
  top: -1px;
}

.events-list .event-meta span.fa-location-arrow {
  margin-left: 10px;
}

.events-list .read-more {
  float: right;
  margin: 0px;
  padding: 0px;
}

.events-list .read-more span {
  margin-left: 5px;
  position: relative;
  top: 1px;
}

/**music gallery**/

.dt-sc-music-audio {
  padding: 20px;
  display: inline-block;
  width: 100%;
  margin: 0px 0px 35px 0px;
}

.dt-sc-music-audio .audio-button {
  float: left;
  margin: 0px 18px 0px 0px;
}

.dt-sc-music-audio .audio-button img {
  display: inline-block;
}

.dt-sc-music-audio h5 {
  margin-bottom: 15px;
}

.dt-sc-music-audio h5 a,
.current-time,
.duration-container {
  color: #ffffff;
}

.time-rail {
  width: 350px;
  display: inline-block;
  height: 10px;
  margin: 0px 21px;
}

.time-rail:before {
  content: "";
  width: 130px;
  background: #000;
  height: 10px;
  float: left;
  border-radius: 2px 0px 0px 2px;
  -webkit-border-radius: 2px 0px 0px 2px;
  -moz-border-radius: 2px 0px 0px 2px;
}

.time-rail:after {
  content: "";
  width: 220px;
  background: #fff;
  height: 10px;
  float: left;
  border-radius: 0px 2px 2px 0px;
  -webkit-border-radius: 0px 2px 2px 0px;
  -moz-border-radius: 0px 2px 2px 0px;
}

.audio-list {
  float: left;
  width: 100%;
  /* display: inline-block; */
  margin: 0px 0px 32px 0px;
}

.audio-list h5 {
  margin: 0px 10px 0px 15px;
  width: 490px;
  overflow: hidden;
  font-size: 16px;
}

.audio-list h5 a {
  color: #1f1f1f;
}

.audio-list h5 {
  display: block;
  background: url(../images/audio-bg.png) 0px center repeat-x;
}

.audio-list .fa {
  font-size: 16px;
}

.audio-list .pause {
  /* display: inline-block; */
  float: left;
  margin: 5px 0px 0px 0px;
}

.audio-list .fa,
.audio-list h5 {
  float: left;
}

.audio-list h5 a {
  white-space: pre;
  padding-right: 10px;
  background: #f0f3f3;
}

/**author**/

.author-rating {
  background: url(../images/rating.png) no-repeat;
  width: 77px;
  height: 13px;
  display: inline-block;
}

.author-rating.rating-4 {
  background-position: 0px -20px;
}

.author-description h5 {
  margin-bottom: 10px;
}

.author-description h5 a {
  color: #1f1f1f;
}

.author-thumb {
  float: left;
  /* display: inline-block; */
  margin: 0px 20px 20px 0px;
  width: 100%;
  text-align: center;
  max-height: 101px;
  max-width: 119px;
  position: relative;
  overflow: hidden;
}

.author-role,
.students-count {
  display: block;
  margin-bottom: 5px;
}

.author-description p {
  float: left;
  display: block;
}

.rating-review a {
  margin: 0px 0px 0px 10px;
}

/*--------------------------------------------------------------

9.0 - Contact Page Styles

--------------------------------------------------------------*/

.admission-form .aligncenter {
  margin-bottom: 0px;
}

.admission-form textarea,
.contact-form textarea,
.comment-form textarea {
  width: 100%;
  padding: 16px 18px;
  background: #ffffff;
  height: 190px;
  border: 1px solid #eaeaea;
}

.dt-sc-contact-info {
  padding: 0px 0px 12px;
  border-bottom: 1px dotted #a2a2a2;
  width: 100%;
  display: block;
  clear: both;
  float: left;
  margin: 0px 0px 25px;
}

.dt-sc-contact-info a {
  text-transform: lowercase;
}

.dt-sc-contact-info i {
  /* display: inline-block; */
  font-size: 20px;
  margin: 2px 15px 10px 0px;
  float: left;
}

.contact_form_outer {
  background: url(../images/contact_form_bg.png) -20px 0px;
  box-shadow: 0px 5px 5px 0px rgba(100, 100, 100, 0.2);
  -webkit-box-shadow: 0px 5px 5px 0px rgba(100, 100, 100, 0.2);
  -moz-box-shadow: 0px 5px 5px 0px rgba(100, 100, 100, 0.2);
  padding: 5px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
}

.contact-form {
  background: #ffffff;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  padding: 30px 30px 10px;
  box-shadow: inset 0px 3px 3px 0px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: inset 0px 3px 3px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: inset 0px 3px 3px 0px rgba(0, 0, 0, 0.1);
}

.contact-form input[type="submit"] {
  margin: 0px;
}

.class_hours h2 {
  color: #ffffff;
  line-height: normal;
  margin: 0px;
  padding: 10px 15px;
  border-radius: 5px 5px 0px 0px;
  -webkit-border-radius: 5px 5px 0px 0px;
  -moz-border-radius: 5px 5px 0px 0px;
}

.class_hours p {
  font-size: 15px;
  display: inline-block;
  width: 100%;
  margin: 0px 0px 15px;
}

ul.class_hours {
  margin: 0px;
  padding: 0px;
  width: 100%;
  background: #ffffff;
}

ul.class_hours li {
  width: 100%;
  display: block;
  padding: 10px 10px 10px;
  margin: 0px 0px 0px;
  border-bottom: 1px solid #f0f0f0;
  font-size: 16px;
  font-weight: bold;
  position: relative;
  float: none;
  position: relative;
}

ul.class_hours li:last-child {
  border-bottom: none;
}

ul.class_hours li span {
  font-style: italic;
  font-weight: normal;
  color: #cf571b;
  float: right;
}

/*--------------------------------------------------------------

10.0 - Sidebar Styles

--------------------------------------------------------------*/

#secondary .widget {
  float: left;
  width: 100%;
  padding-bottom: 30px;
  margin: 0px 0px 0px;
}

#secondary h3.widgettitle {
  border-bottom: 2px dotted #a2a2a2;
}

#secondary .widget_categories ul li {
  position: relative;
  float: none;
  display: block;
  padding: 0px;
  margin: 0px;
}

#secondary .widget_categories ul li a {
  font-size: 16px;
  padding: 0px 45px 0px 5px;
  margin-bottom: 5px;
  display: block;
  line-height: 35px;
  border-bottom: 1px dotted #a2a2a2;
}

#secondary .widget_categories ul li:last-child a {
  border-bottom: none;
  margin-bottom: 0px;
}

#secondary .widget_categories ul li a span {
  position: absolute;
  right: 0px;
  color: #cf571b;
  font-style: italic;
}

.widget ul {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 0px;
}

#secondary .tagcloud a {
  float: left;
  background: #df9b00;
  color: #ffffff;
  padding: 4px 9px;
  margin: 0px 10px 10px 0px;
  border-radius: 5px 0px 5px 0px;
  -webkit-border-radius: 5px 0px 5px 0px;
  -moz-border-radius: 5px 0px 5px 0px;
  border-bottom: 3px solid #ca8e06;
}

#secondary .tagcloud a:hover {
  background: #cf571b;
  border-bottom: 3px solid #b54913;
}

/*--------------------------------------------------------------

11.0 - Footer Styles

--------------------------------------------------------------*/

footer {
  width: 100%;
  margin: 0px;
  padding: 65px 0px 0px;
  float: left;
  clear: both;
  background-image: url(../images/footer_bg.png);
  background-color: #ffffff;
  position: relative;
  background-position: center top;
}

footer:before {
  content: "";
  background: url(../images/footer_top_bg.png) center top no-repeat;
  position: absolute;
  top: -46px;
  left: 0px;
  width: 100%;
  height: 46px;
  background-size: cover;
}

.footer-widgets-wrapper {
  padding-bottom: 20px;
  width: 100%;
  clear: both;
  float: left;
}

/**footer widget styles**/

footer h3.widgettitle {
  padding-bottom: 25px;
}

footer h3.widgettitle.red_sketch {
  background: url(../images/red_sketch.png) left bottom no-repeat;
}

footer h3.widgettitle.green_sketch {
  background: url(../images/green_sketch.png) left bottom no-repeat;
}

footer h3.widgettitle.yellow_sketch {
  background: url(../images/yellow_sketch.png) left bottom no-repeat;
}

footer h3.widgettitle.steelblue_sketch {
  background: url(../images/steelblue_sketch.png) left bottom no-repeat;
}

h3.widgettitle {
  font-size: 22px;
  padding-bottom: 10px;
  margin-bottom: 25px;
  text-transform: capitalize;
}

footer h3 a {
  color: #2f2f2f;
}

footer .widget {
  float: left;
  width: 100%;
  padding-bottom: 20px;
  margin: 0px 0px 0px;
}

footer .widget ul {
  width: 100%;
  margin: 0px;
  padding: 0px;
}

footer .widget.widget_text ul li {
  width: 100%;
  font-size: 14px;
  font-weight: normal;
  display: block;
  position: relative;
  line-height: 20px;
  padding: 7px 0px 7px 20px;
}

footer .widget.widget_text ul li:before {
  content: "\f00c";
  text-decoration: inherit;
  font-size: 16px;
  position: absolute;
  top: 7px;
  left: 0px;
}

footer .widget.widget_text ul li a,
footer .dt-sc-contact-info a {
  color: #5c5c5c;
  display: block;
}

footer .widget_recent_entries ul li h6 {
  font-size: 14px;
  font-weight: normal;
  margin-bottom: 10px;
  font-family: inherit;
}

footer .widget_recent_entries ul li a {
  text-transform: none;
  color: #5c5c5c;
}

footer .widget_recent_entries ul li {
  width: 100%;
  float: left;
  margin: 0px 0px 10px;
}

footer .widget_recent_entries ul li a:hover,
.widget_links ul li:hover a:before,
.widget_links ul li:hover a {
  color: #a99479;
}

footer .widget_recent_entries ul li img {
  color: #efebe6;
  font-size: 14px;
  border: 2px solid #818181;
}

footer .widget_recent_entries ul li span {
  color: #818181;
  display: block;
  float: left;
}

footer .widget_recent_entries ul li > a {
  float: left;
  margin-right: 10px;
}

footer .widget_recent_entries ul li img {
  width: 60px;
  height: 60px;
  float: left;
}

footer .widget_recent_entries ul li:hover img {
  border: 2px solid #008c99;
}

footer #tweets_container ul.tweet_list {
  overflow: hidden;
}

footer #tweets_container ul.tweet_list li {
  clear: both;
  display: block;
  padding-left: 30px;
}

footer #tweets_container li:before {
  content: "";
  background: url(../images/twitter_icon.png) no-repeat;
  float: left;
  width: 21px;
  height: 15px;
  margin: 5px 0px 0px -30px;
}

footer .dt-sc-contact-info {
  clear: both;
  float: left;
  width: 100%;
  margin: 0px 0px 10px;
  padding: 0px;
  border: 0px;
}

footer .dt-sc-contact-info svg {
  font-size: 28px;
  float: left;
  margin: 4px 10px 0px 0px;
  padding-bottom: 12px;
}

.widget.mailchimp p {
  text-transform: uppercase;
  font-weight: bold;
}

.mailchimp-form p {
  border: 1px solid #dddddd;
  background-color: transparent;
  border-right: 0px;
  padding: 10px 12px;
  float: left;
  border-radius: 5px 0px 0px 5px;
  -moz-border-radius: 5px 0px 0px 5px;
  -webkit-border-radius: 5px 0px 0px 5px;
}

.mailchimp-form input[type="email"] {
  border: none;
  background-color: transparent;
  outline: none;
  width: 110px;
  font-family: inherit;
  font-size: inherit;
  padding: 0px;
}

.mailchimp-form input[type="email"]:focus {
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
}

.mailchimp-form p span {
  padding-right: 5px;
}

.widget .mailchimp-form input[type="submit"] {
  padding: 0px 14px;
  line-height: 40px;
  height: 44px;
  border: 1px solid #008c99;
  color: #fff;
  cursor: pointer;
  outline: none;
  border-radius: 0px 5px 5px 0px;
  -moz-border-radius: 0px 5px 5px 0px;
  -webkit-border-radius: 0px 5px 5px 0px;
  display: inherit;
}

.widget .mailchimp-form input[type="submit"]:hover {
  background: #df9b00;
  border: 1px solid #df9b00;
}

.copyright {
  clear: both;
  float: left;
  width: 100%;
  margin: 0px;
  padding: 15px 0px 10px;
  background: url(../images/footer_strip.jpg) center top repeat-x #008c99;
}

.copyright p {
  float: left;
  color: #fff;
}

.copyright p a {
  color: #fff;
}

.copyright .footer-links {
  float: right;
  margin: 0px;
  padding: 0px;
}

.copyright .footer-links ul,
.class_hours ul.dt-sc-social-icons {
  margin: 0px;
  padding: 0px;
  float: left;
}

.copyright .footer-links ul li,
.class_hours ul.dt-sc-social-icons li {
  margin: 0px 8px 0px 0px;
}

.copyright .footer-links ul li:last-child,
.class_hours ul.dt-sc-social-icons li:last-child {
  margin: 0px;
}

.copyright .footer-links ul li a,
.class_hours ul.dt-sc-social-icons li a {
  width: 35px;
  height: 35px;
  display: block;
  line-height: 40px;
  text-align: center;
  padding: 0px;
}

.copyright .footer-links ul li.facebook:hover a,
.class_hours ul.dt-sc-social-icons li.facebook a {
  background: #3c5b9b;
}

.copyright .footer-links ul li.instagram:hover a,
.class_hours ul.dt-sc-social-icons li.instagram a {
  background: #fbad50;
}

.copyright .footer-links ul li.gplus:hover a,
.class_hours ul.dt-sc-social-icons li.gplus a {
  background: #ba3526;
}

.copyright .footer-links ul li.pinterest:hover a,
.class_hours ul.dt-sc-social-icons li.pinterest a {
  background: #ca1325;
}

footer .footer-links p {
  float: left;
  background: url(../images/footer_arrow.png) right 7px no-repeat;
  padding: 0px 50px 0px 0px;
  margin: 7px 0px 0px 0px;
}

.copyright .copyright-info {
  margin: 0px;
  line-height: 35px;
}

.back-to-top {
  position: fixed;
  bottom: 8px;
  right: 30px;
  width: 42px;
  height: 175px;
  line-height: 43px;
  background: url(../images/go_to_top.gif) no-repeat;
  font-size: 10px;
  text-indent: -9999px;
  text-align: center;
  display: none;
  z-index: 9999;
}

.vactor {
  position: absolute;
  top: 0px;
  /* right: 350px; */
  width: 350px;
  height: 175px;
  line-height: 43px;
  font-size: 10px;
  text-indent: -9999px;
  text-align: center;
  display: inline; /*z-index:9999;*/
}

.sticky {
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100%;
  animation: slide 0.3s;
}

@media only screen and (max-width: 479px) and (min-width: 320px) {
  .vactor {
    position: absolute;

    top: 0px;

    right: -238px;

    width: 350px;

    height: 175px;

    line-height: 43px;

    background: url(../images/animation_character_2.gif) no-repeat;

    font-size: 10px;

    text-indent: -9999px;

    text-align: center;

    display: inline;
  }
}

.franchise {
  height: 94px;
}

header #menu-container ul {
  margin-bottom: 0px !important;
}

.aboutVisionMisson {
  font-size: 36px;
  margin: 25px;
  line-height: 90px;
  color: #ffffff;
}

.fascination_img {
  width: 100%;
}

.add-slider-wrapper.teacherTraining {
  padding: 8% 9% 0px 8.8% !important;
}

/*.back-to-top:hover{opacity:0.8;}*/

@media (max-width: 768px) {
  .logo {
    width: 64%;
  }
}

@media (max-width: 1024px) {
  header ul.dt-sc-social-icons li a {
    width: 40px;
  }
}

@media (max-width: 768px) {
  header ul.dt-sc-social-icons {
    display: none !important;
  }
}

@media (min-width: 992px) {
  ul {
    padding-left: 0px !important;
  }
}

/* Media Query for Mobile Devices */
@media (max-width: 480px) {
  .franchise {
    display: none;
  }
  .logo {
    width: 100%;
  }
}

/*Franchise Form style*/
.franchise_requirment {
  margin: 5.5rem 5rem;
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
}

.form_title {
  text-align: center;
}

.form_text {
  font-size: 1.2rem;
}

.form_hr {
  width: 70%;
  margin: auto;
}

.form_container {
  width: 90%;
  display: flex;
  flex-direction: column;
  border: 1px solid #000;
  border-radius: 2rem;
  margin: 5rem auto;
  padding: 1.5rem 1rem;
}

.form_header {
  display: flex;
  height: 10rem;
  margin-bottom: 3rem;
  align-items: center;
}

.form_logo {
  width: 9rem;
  height: 9rem;
}

.watermark_logo {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.form_heading {
  display: flex;
  flex-grow: 1;
  justify-content: center;
}

.label {
  font-size: 1.5rem;
}

.textborder {
  z-index: 9;
  border-radius: 5px;
  width: 49rem;
}

@media only screen and (max-width: 600px) {
  .watermark {
    display: none;
  }

  .form_container {
    padding: 1.5rem 0.6rem;
  }

  .form_logo {
    width: 6rem;
    height: 6rem;
  }

  .form_header {
    height: 6rem;
    column-gap: 1rem;
  }

  .form_inputs {
    width: 100%;
    row-gap: 1rem;
    flex-direction: column;
    display: flex;
  }

  .inputs {
    width: 80%;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 0.75rem;
    margin: auto;
    display: flex;
  }

  .txtField {
    width: 100%;
  }

  .textborder {
    width: 100%;
  }
}

@media only screen and (min-width: 600px) {
  .watermark {
    display: none;
  }

  .form_inputs {
    width: 100%;
  }

  .txtField {
    width: 100%;
  }

  .textborder {
    width: 97%;
  }

  .inputs {
    flex-direction: column;
    align-items: flex-start;
  }
}

@media only screen and (min-width: 768px) {
  .watermark {
    width: 35rem;
    left: 9rem;
    top: 150rem;
  }

  .form_inputs {
    width: 95%;
    margin: 1rem 0rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 1rem;
  }

  .inputs {
    width: 95%;
    column-gap: 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .label {
    width: 45%;
  }

  .txtField {
    width: 100%;
  }

  .watermark {
    width: 35rem;
    height: 55rem;
    position: absolute;
    left: 12rem;
    top: 153rem;
    opacity: 0.2;
    display: block;
  }
}

@media only screen and (min-width: 992px) {
  .watermark {
    width: 40rem;
    left: 15rem;
    top: 125rem;
  }

  .form_inputs {
    width: 95%;
    margin: 1rem 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 1rem;
  }

  .inputs {
    width: 95%;
    display: flex;
    flex-direction: row;
    column-gap: 2rem;
    align-items: center;
    justify-content: space-between;
  }

  .label {
    width: 45%;
  }

  .txtField {
    width: 100%;
  }

  .watermark {
    width: 40rem;
    height: 55rem;
    position: absolute;
    left: 22rem;
    top: 120rem;
    opacity: 0.2;
    display: block;
  }
}

@media only screen and (min-width: 1200px) {
  .form_inputs {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: 1rem auto;
    align-items: center;
    row-gap: 1rem;
  }

  .inputs {
    display: flex;
    column-gap: 2rem;
    align-items: center;
    justify-content: space-between;
    width: 80%;
    flex-direction: row;
  }

  .txtField {
    width: 50rem;
  }

  .submit.css-sghohy-MuiButtonBase-root-MuiButton-root {
    width: 15rem;
    height: 3rem;
  }

  .watermark {
    width: 55rem;
    height: 55rem;
    position: absolute;
    left: 35rem;
    top: 100rem;
    opacity: 0.2;
    display: block;
  }
}

@media only screen and (min-width: 1441px) {
  .form_inputs {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: 1rem auto;
    align-items: center;
    row-gap: 1rem;
  }

  .inputs {
    display: flex;
    column-gap: 2rem;
    align-items: center;
    justify-content: flex-start;
    width: 80%;
    flex-direction: row;
  }

  .label {
    font-size: 1.5rem;
    width: 25%;
  }

  .txtField {
    width: 50rem;
  }

  .submit.css-sghohy-MuiButtonBase-root-MuiButton-root {
    width: 15rem;
    height: 3rem;
  }

  .watermark {
    width: 55rem;
    height: 55rem;
    position: absolute;
    /* left: 50rem; */
    top: 100rem;
    opacity: 0.2;
    display: block;
  }

  .textborder {
    z-index: 9;
    border-radius: 5px;
    width: 74%;
  }
}

/* ============================= Contact US style ===============================*/

.contact__section {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: #112037;
}

.contact__section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 120%;
  background: #105463;
}

.first__container {
  position: relative;
  min-width: 1100px;
  min-height: 550px;
  display: flex;
}

.contact__info {
  position: absolute;
  top: 40px;
  width: 350px;
  height: calc(100% - 80px);
  background: #112037;
  z-index: 1;
  padding: 40px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0 20px 25px rgba(0, 0, 0, 0.15);
}

.contact__form {
  position: absolute;
  padding: 70px 50px;
  padding-left: 250px;
  margin-left: 150px;
  width: calc(100% - 150px);
  height: 105%;
  background: #ffffff;
  box-shadow: 0 50px 50px rgba(0, 0, 0, 0.25);
}

.contact__title {
  color: #ffffff;
  margin-bottom: 2.5rem;
}

.info {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: -3rem;
}
.home__social-link {
  color: #ffffff;
  font-size: 1.5rem;
}

.info li {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #ffffff;
  column-gap: 2rem;
}

.info li:nth-child(4) {
  margin-top: 4rem;
}

.contact__icon {
  font-size: 1.6rem;
  width: max-content;
}

.contact__social {
  display: flex;
  column-gap: 2rem;
}

.form__title {
  color: #000000;
  margin-bottom: 1rem;
}

.form__box {
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 30px;
}

.input__box {
  position: relative;
  margin-bottom: 2.5rem;
}

.w50 {
  width: 47%;
}

.w100 {
  width: 100%;
}

.form__input,
.form__text {
  width: 100%;
  resize: none;
  padding: 5px 0;
  font-weight: 300;
  color: #000000;
  border: none;
  outline: none;
  border-bottom: 1px solid #777;
}

.form__text {
  height: 120px;
}

.form__span {
  position: absolute;
  left: 0;
  padding: 5px 0;
  pointer-events: none;
  font-weight: 300;
  transition: 0.3s;
}

.form__input:focus ~ .form__span,
.form__input:valid ~ .form__span,
.form__text:focus ~ .form__span,
.form__text:valid ~ .form__span {
  transform: translateY(-20px);
  font-weight: 400;
  letter-spacing: 1px;
  color: #112037;
}

input[type="text"]:focus,
input[type="email"]:focus,
textarea:focus {
  box-shadow: none;
}

.form__input[type="submit"] {
  position: relative;
  cursor: pointer;
  background: #015057;
  color: #ffffff;
  border: none;
  max-width: 150px;
  padding: 12px;
}

.form__input[type="submit"]:hover {
  background: #015037;
}

@media (max-width: 1200px) {
  .first__container {
    width: 90%;
    min-width: auto;
    margin: 20px;
    margin-bottom: 35px;
    box-shadow: 0 20px 50px rgba(0, 0, 0, 0.2);
  }

  .contact__info {
    top: 0;
    height: 550px;
    position: relative;
    box-shadow: none;
  }

  .contact__form {
    position: relative;
    width: calc(100% - 350px);
    padding-left: 0;
    margin-left: 0;
    padding: 40px;
    height: 550px;
    box-shadow: none;
  }
}

@media (max-width: 991px) {
  .contact__section {
    background: #105463;
  }
  .contact__section::before {
    display: none;
  }

  .first__container {
    display: flex;
    flex-direction: column-reverse;
  }

  .contact__form {
    width: 100%;
    height: auto;
  }

  .contact__info {
    width: 100%;
    height: auto;
  }

  .info li {
    margin-bottom: 0.75rem;
  }

  .info li:nth-child(4) {
    margin-top: 1rem;
  }
}

@media (max-width: 600px) {
  .contact__form {
    padding: 25px;
  }

  .contact__info {
    padding: 25px;
    flex-direction: column;
    align-items: flex-start;
  }

  .input__box.w50 {
    width: 100%;
  }
}

.registration {
  margin: 3rem;
  padding: 1rem 0.7rem;
}

.news_container {
  width: 99vw;
  height: 90vh;
  position: relative;
  background-image: url(../images/newsletter1.jpg);
  background-repeat: no-repeat;
}

.news_box {
  position: absolute;
  width: 45rem;
  height: 15rem;
  border-radius: 10px;
  background-color: rgba(255, 69, 0, 0.6);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  column-gap: 1rem;
  top: 20rem;
  left: 15rem;
  padding: 1rem 4rem;
}

.news_subscription {
  width: 100%;
  display: flex;
  column-gap: 1rem;
  justify-content: space-between;
}

@media only screen and (max-width: 600px) {
  .news_box {
    top: 10rem;
    left: 1rem;
    width: 22rem;
    height: 25rem;
  }

  .news_subscription {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
  }
}

@media only screen and (min-width: 600px) {
  .news_container {
    height: 100vh;
  }
  .news_box {
    top: 4rem;
    left: 2rem;
    width: 35rem;
    height: 20rem;
  }

  .news_subscription {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
  }
}

@media only screen and (min-width: 768px) {
  .news_box {
    top: 8rem;
    left: 2rem;
    width: 40rem;
    height: 25rem;
    justify-content: space-around;
    padding: 5rem 1.5rem;
  }

  .news_subscription {
    display: flex;
    flex-direction: row;
  }
}

@media only screen and (min-width: 992px) {
  .news_box {
    top: 10rem;
    left: 2rem;
    width: 45rem;
    height: 20rem;
  }

  .news_subscription {
    display: flex;
    flex-direction: row;
  }
}

@media only screen and (min-width: 1200px) {
  .news_box {
    top: 10rem;
    left: 5rem;
    width: 55rem;
    height: 20rem;
  }

  .news_subscription {
    display: flex;
    flex-direction: row;
  }
}

@media only screen and (min-width: 1441px) {
  .news_container {
    height: 75vh;
  }
  .news_box {
    top: 15rem;
    left: 10rem;
    width: 55rem;
    height: 20rem;
    padding: 5rem 5rem;
  }

  .news_subscription {
    display: flex;
    flex-direction: row;
  }
}

.txtField_contact {
  width: 20rem;
}

.student_name {
  display: flex;
  align-items: center;
  column-gap: 5rem;
}

.student_label {
  width: 33rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.student_sibling {
  display: flex;
  flex-direction: column;
}

.student_address {
  width: 57rem;
}

.student_l {
  font-size: 1.2rem;
}

.txtField_sibling {
  width: 16rem;
}

.parent_details {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}

.txtField_parent {
  width: 30rem;
}

.checkbox {
  display: flex;
  column-gap: 1rem;
}

.checkbox_in {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}

@media only screen and (max-width: 600px) {
  .txtField_contact {
    width: 15rem;
  }

  .student_name {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 2rem;
  }

  .student_label {
    width: 18rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .student_address {
    width: 22rem;
  }

  .txtField_parent {
    width: 20rem;
  }

  .student_radio {
    margin-left: 20px;
  }
}

@media only screen and (min-width: 768px) {
  .txtField_contact {
    width: 15rem;
  }

  .student_name {
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    /* row-gap: 2rem; */
    column-gap: 2rem;
  }

  .student_label {
    width: 20rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .student_address {
    width: 42rem;
  }

  .txtField_parent {
    width: 20rem;
  }

  .student_radio {
    margin-left: 20px;
  }

  .txtField_sibling {
    width: 9rem;
  }
}

@media only screen and (min-width: 992px) {
  .txtField_contact {
    width: 20rem;
  }

  .student_name {
    display: flex;
    align-items: center;
    column-gap: 2rem;
  }

  .student_label {
    width: 30rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .student_address {
    width: 52rem;
  }

  .txtField_parent {
    width: 25rem;
  }

  .student_radio {
    margin-left: 20px;
  }

  .txtField_sibling {
    width: 12rem;
  }
}

@media only screen and (min-width: 1200px) {
  .txtField_contact {
    width: 25rem;
  }

  .student_name {
    display: flex;
    align-items: center;
    column-gap: 6rem;
  }

  .student_label {
    width: 35rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .student_address {
    width: 62rem;
  }

  .txtField_parent {
    width: 30rem;
  }

  .student_radio {
    margin-left: 20px;
  }

  .txtField_sibling {
    width: 15rem;
  }
}
